const monthsTariffMappings = {
  36: 2.836,
  48: 2.216,
  60: 1.845,
  72: 1.6,
  84: 1.425,
};

const getMonthlyLeasingCost = (leasingPeriodInMonths, loanAmount) => {
  const tariff = monthsTariffMappings[leasingPeriodInMonths];

  return loanAmount * (tariff * 0.01);
};

export { getMonthlyLeasingCost };
