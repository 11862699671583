const termsAndConditions = [
  {
    title: "Kapitel 1 Allmänt",
    points: [
      "De allmänna villkoren gäller för alla avtal och erbjudanden förknippade med AS Maskiner Sverige AB.",
      "Om parterna är skriftligen överens kan individuella villkor komplettera de allmänna villkoren.",
      "Kunden anses ha accepterat de allmänna villkoren vid lagd order.",
      "Inga Avvikande villkor från motparten är bindande för AS Maskiner Sverige AB om detta inte skriftligen godkänts av AS Maskiner Sverige AB.",
      "Försäljningar, köp och andra avtal med AS Maskiner Sverige AB utgör ett led i näringsverksamhet. AS Maskiner Sverige AB godkänner inte köp eller försäljning till konsumenter.",
      "Kunden förbinder sig att köpta produkter används som ett led i näringsverksamheten.",
      "Alla kunder hos AS Maskiner Sverige AB måste ange bland annat organisationsnummer, säte och företagsadress.",
      "AS Maskiner Sverige AB säljer produkter i nytt och begagnat skick. Om det inte framgår av marknadsföring, annonser eller andra utfästelser att tillgängliga produkter är i nyskick säljs maskinen som begagnad.",
      "Alla begagnade maskiner säljs i befintligt skick.",
      "AS Maskiner Sverige AB ansvarar bara för fel enligt kapitel 6.",
    ],
  },
  {
    title: "Kapitel 2 Anbud och accept",
    points: [
      "Alla anbud, exempelvis offerter eller andra erbjudanden är icke bindande. För att anbudet ska vara bindande krävs en skriftlig orderbekräftelse. Detta gäller även vid ett tidsbegränsat anbud.",
      "Fram till att AS Maskiner Sverige AB skickat en orderbekräftelse är alla produkter tillgängliga för försäljning.",
      "För ändringar eller tillägg till ingångna avtal krävs alltid skriftligt godkännande från AS Maskiner Sverige AB.",
      "Alla uppgifter såsom exempelvis vikt, mått, utseende, ritningar eller andra handlingar om produkter i bland annat erbjudanden, annonsering eller annan marknadsföring, offerter och avtal från AS Maskiner Sverige AB är ungefärliga och kan inte garanteras av AS Maskiner Sverige AB om det inte är avgörande för avtalet att uppgifterna är precisa. Avvikelser till följd av bland annat myndighetsbestämmelser, lagstiftning eller förbättringsåtgärder kan inte göras gällande mot AS Maskiner Sverige AB.",
      "AS Maskiner Sverige AB förbehåller sig rätten att bland annat byta ut tekniska komponenter i produkterna i den mån användningen av produkten inte påverkas negativt.",
    ],
  },
  {
    title: "Kapitel 3 Priser och betalning",
    points: [
      "Alla priser för nya och begagnade produkter avser enbart produkten. Priserna är exklusive kostnader för exempelvis packning, nedmontering, frakt och tullkostnader.",
      "Priserna är exklusive Moms och eventuell skatt enligt gällande lagstiftning.",
      "Om inte annat överenskommits ska betalning ske senast 10 dagar efter köpets ingående.",
      "Om inte annat överenskommits måste betalning ske till AS Maskiner Sverige AB enligt anvisad betalningsmetod.",
      "AS maskiner Sverige AB har rätt att innehålla såld produkt om inte betalning skett enligt villkor som framgår av ovan.",
      "Om kunden inte har betalat inom 10 dagar äger AS Maskiner Sverige AB rätt att häva köpet.",
      "Vid hävning av köpet har AS Maskiner Sverige AB en oinskränkt rätt till produkten.",
      "Kunden står för alla eventuella kostnader vid hävning till följd av betalningsdröjsmål.",
    ],
  },
  {
    title: "Kapitel 4 Leverans",
    points: [
      "Leveranstiden överenskommes mellan parterna. Innan leverans ska alla mellanhavanden, såsom exempelvis tekniska- och affärsmässiga frågor, vara lösta. Om inte alla mellanhavanden är lösta har AS Maskiner Sverige AB rätt att förlänga leveranstiden.",
      "Kunden är skyldig att lämna relevanta och korrekta uppgifter för leveransen till AS Maskiner Sverige AB.",
      "Kunden är skyldig att följa alla anvisningar från AS Maskiner Sverige AB, av relevans för leveransen, såsom till exempel datum för undersökning av varan.",
      "Risken för produkten övergår på kunden så fort produkten blivit avlämnad till speditör eller transportör. Detta gäller även för delleveranser.",
      "Kunden ansvarar för avlastning av köpt produkt.",
      "Om AS Maskiner Sverige AB inte kan leverera varan i tid till följd av att köparen inte fullgjort sina skyldigheter går risken för produkten över på köparen. Kunden ska, när ett godkännande krävs för överlämning av produkten, utan dröjsmål godkänna en sådan överlämning. Detta gäller även när överlämning sker från tredje part.",
      "För det fall AS Maskiner Sverige AB inte kan leverera produkten i tid till följd av exempelvis arbetskonflikter, krig, miljökatastrofer eller andra händelser utanför AS Maskiner Sverige AB kontroll, ska leveranstiden förlängas.",
      "Kunden kan inte heller göra gällande påföljder enligt lag för det fall leveranstiden förlängs till följd av andra händelser utanför AS Maskiner Sverige AB kontroll innefattande exempelvis arbetskonflikter, krig, miljökatastrofer.",
      "Om AS Maskiner Sverige AB inte kan leverera produkten av någon anledning ska ansvaret begränsas för AS Maskiner Sverige AB i enlighet med kapitel 6.",
      "Kunden ansvarar för att alla produkter är försäkrade under transporten mot exempelvis stöld, annat brott, skador såsom till exempel brand- eller vattenskador. Vid anmodan ska AS Maskiner Sverige AB försäkra produkterna under transporten mot ersättning från kunden.",
    ],
  },
  {
    title: "Kapitel 5 Garanti och skick",
    points: [
      "Alla nya produkter har en garantitid på 365 dagar från att kunden erhållit varan genom leverans.",
      "Alla begagnade produkter säljs i befintligt skick.",
      "AS Maskiner Sverige AB lämnar ingen garanti på begagnade maskiner.",
      "AS Maskiner Sverige AB garanterar inte att begagnade produkter säljs med tillbehör såsom exempelvis manualer eller annan dokumentation och tekniska tillbehör.",
      "AS Maskiner Sverige AB är inte ansvariga för att produkten inte uppfyller nuvarande eller framtida säkerhetskrav.",
      "AS Maskiner Sverige AB ansvarar inte för fel i exempelvis installationer, hantering, och användning av köparen eller av denne anlitad entreprenör.",
    ],
  },
  {
    title: "Kapitel 6 Ansvar",
    points: [
      "AS Maskiner Sverige AB ansvarar enbart för direkta skador på såld produkt och bara i den mån skadan uppkommit genom bedrägeri, vilseledande, grov vårdslöshet eller avsiktliga fel om inget annat följer av tvingande lagstiftning.",
      "AS Maskiner Sverige AB ansvarar inte för några indirekta skador exempelvis följdskador, utebliven vinst, avbrott i affärsverksamhet eller uteblivna affärsmöjligheter.",
      "Kunden är skyldig att vidta skäliga åtgärder för att begränsa sin skada. Om skäliga åtgärder inte vidtagits kan kunden bli ansvarig att bära en del av kostnaden för skadan.",
      "Ansvar för felaktiga utfästelser begränsas till uppsåt och grov oaktsamhet.",
      "Kunden kan inte göra gällande felaktigheter i varan som han måste antas ha känt till vid köpet.",
      "Kunden uppmanas att undersöka varan innan köpet och kan inte göra gällande fel som han borde ha märkt vid en med omsorg företagen undersökning av varan.",
    ],
  },
  {
    title: "Kapitel 7 Reklamation",
    points: [
      "Om en kund vill invända mot köpt produkt ska reklamation ske inom skälig tid från att risken för varan övergick på köparen, dock senast inom sex månader.",
      "Även när lagstiftning stipulerar annan tid för preskription och reklamation ska tiden för reklamation gälla enligt de allmänna villkoren.",
      "Om en kund handlat som konsument trots att det är i strid med de allmänna villkoren ska minsta möjliga preskriptionstid enlig gällande konsumentköpslagstiftning gälla.",
    ],
  },
  {
    title: "Kapitel 8 Rättslig reglering",
    points: [
      "Svensk lagstiftning gäller i den mån de allmänna villkoren inte reglerar avtalet mellan parterna.",
      "AS Maskiner Sverige AB har sitt juridiska säte i Stockholm. Alla tvister ska prövas av Stockholms Tingsrätt. AS Maskiner Sverige AB har emellertid rätt att få tvisten prövad där kunden har sitt juridiska säte.",
    ],
  },
];

export { termsAndConditions };
