import React from "react";
import "./button.css";

const Button = ({
  loading,
  children,
  disabled = false,
  sx,
  type,
  black = false,
  ...props
}) => (
  <button
    className={`${
      black ? "bg-black" : "bg-primary hover:bg-orange-600"
    } text-white py-2 px-4 transition duration-300 ease-in-out relative ${
      loading || disabled ? "opacity-50 cursor-not-allowed" : ""
    } ${sx ? sx : ""}`.trim()}
    disabled={loading || disabled}
    type={type ? type : "button"}
    {...props}
  >
    {loading && <span className="button-spinner" />}
    {children}
  </button>
);

export default Button;
