import React, { useContext, useEffect, useState } from "react";
import { JobsContext } from "../context/JobsContext";
import { Link, useParams } from "react-router-dom";
import Heading from "../components/heading";
import { Helmet } from "react-helmet-async";

const JobDescription = () => {
  let { id } = useParams();
  const { jobs } = useContext(JobsContext);
  const [job, setJob] = useState(null);

  useEffect(() => {
    const job = jobs.find((job) => job.id === id);
    setJob(job);
  }, [id, jobs]);

  return (
    job && (
      <div>
        <Helmet>
          <title>AS Maskiner - Jobb - {job.title}</title>
          <meta
            name="description"
            content={`Sök till oss på AS Maskiner för rollen som ${job.title} i ${job.location}. Bli del av ett team som värdesätter innovation och flexibilitet.`}
          />
          <meta
            name="keywords"
            content={`jobb, ${job.title}, ${job.location}, AS Maskiner, lediga jobb, karriär, jobbmöjligheter, CNC maskiner, snickerimaskiner`}
          />
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "JobPosting",
              "title": "${job.title}",
              "hiringOrganization": {
                "@type": "Organization",
                "name": "AS Maskiner",
                "sameAs": "https://www.asmaskiner.se",
                "logo": "https://www.asmaskiner.se/logo.png"
              },
              "jobLocation": {
                "@type": "Place",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "${job.location}"
                }
              }
            }`}
          </script>
        </Helmet>
        <div className="bg-secondary flex flex-col items-center justify-center p-4 min-h-[20vh] text-white">
          <h2 className="text-3xl lg:text-5xl font-bold">
            AS Maskiner Sverige AB
          </h2>
          <p className="mt-4 text-sm sm:text-lg max-w-sm sm:max-w-none text-center">
            Sveriges största utbud av begagnade kvalitetsmaskiner för trä- och
            plastindustrin
          </p>
        </div>
        <div className="container mx-auto max-w-7xl p-8">
          <div className="mb-4">
            <Link to="/jobs" className="font-bold">
              ← Alla lediga jobb
            </Link>
          </div>
          <Heading sx="mb-4 w-fit" type="h1">
            {job.title}
          </Heading>
          <div>{job.description}</div>
        </div>
      </div>
    )
  );
};

export default JobDescription;
