import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { addSellProposalEvent, getSellProposals } from "../httpService";
import { MachineContext } from "../context/MachineContext";
import Button from "../components/button";
import Pagination from "../components/pagination";

const MAX_ITEMS_PER_PAGE = 10;

const QuotationManagement = () => {
  const navigate = useNavigate();
  const { isAdmin, getJwt, initialized } = useContext(UserContext);
  const { machines } = useContext(MachineContext);
  const [sellProposals, setSellProposals] = useState([]);
  const [totalPages, setTotalPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setTotalPages(Math.ceil(sellProposals.length / MAX_ITEMS_PER_PAGE) || 1);
    setCurrentPage(1);
  }, [sellProposals]);

  useEffect(() => {
    getSellProposals({ jwt: getJwt() }).then(setSellProposals);
  }, []);

  useEffect(() => {
    if (initialized && !isAdmin) {
      navigate("/");
    }
  }, [isAdmin, initialized]);

  const handleAddEventClick = (proposalId) => {
    const description = window.prompt("Lägg till händelse");

    if (description) {
      addEvent(proposalId, description);
    }
  };

  const addEvent = (proposalId, description) => {
    addSellProposalEvent({
      sellProposalId: proposalId,
      description: description,
      jwt: getJwt(),
    }).then((updatedSellProposal) =>
      setSellProposals((currentProposals) =>
        currentProposals.map((proposal) =>
          proposal.id === updatedSellProposal.id
            ? updatedSellProposal
            : proposal
        )
      )
    );
  };

  return (
    <div className="container mx-auto p-8 max-w-7xl">
      <Helmet>
        <title>AS Maskiner - Offerter</title>
      </Helmet>
      <h2 className="text-3xl font-bold mb-8">Offertförfrågningar</h2>
      <div>
        {sellProposals
          .slice(
            (currentPage - 1) * MAX_ITEMS_PER_PAGE,
            MAX_ITEMS_PER_PAGE * currentPage
          )
          .map((proposal) => {
            const machine = machines.find((m) => m.id == proposal.machineId);

            return (
              <div
                key={proposal.id}
                className="mb-6 p-4 shadow rounded-lg bg-white"
              >
                <div className="flex justify-between">
                  <h3 className="text-2xl font-semibold">
                    {proposal.firstName} {proposal.lastName}
                  </h3>
                  {machine && (
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`/machines/${
                        machine.id
                      }/quotation/create?organisationNumber=${
                        proposal.organizationNumber
                      }&companyReference=${
                        proposal.firstName + " " + proposal.lastName
                      }&companyReferenceTelephone=${
                        proposal.phoneNumber
                      }&sellProposalId=${proposal.id}`}
                    >
                      <Button
                        onClick={() => addEvent(proposal.id, "Offert skapad")}
                      >
                        Skapa offert
                      </Button>
                    </Link>
                  )}
                </div>

                {machine ? (
                  <a
                    href={`https://www.asmaskiner.se/machines/${proposal.machineId}`}
                    target="_blank"
                    className="text-blue-500"
                  >
                    Maskin
                  </a>
                ) : (
                  <div className="text-red-500">Maskinen finns inte kvar</div>
                )}
                <p>Email: {proposal.email}</p>
                <p>Telefonnummer: {proposal.phoneNumber}</p>
                <p>Organisationsnummer: {proposal.organizationNumber}</p>
                <p>Maskinfabrikat: {proposal.manufacturer}</p>
                <p>Maskinkategori: {proposal.category}</p>
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <strong>Kundens kommentar:</strong>
                  <p>{proposal.comment}</p>
                </div>
                <div>
                  <strong>Händelser:</strong>
                  {proposal.events.map((event, index) => (
                    <div key={index}>
                      <p>
                        {new Date(event.timestamp).toLocaleString()} -{" "}
                        {event.description}
                      </p>
                      {event.adminEmail && (
                        <p className="text-sm text-gray-500">
                          Skapad av: {event.adminEmail}
                        </p>
                      )}
                    </div>
                  ))}
                  <Button
                    onClick={() => handleAddEventClick(proposal.id)}
                    sx="mt-2"
                  >
                    Lägg till händelse
                  </Button>
                </div>
              </div>
            );
          })}
        <Pagination
          currentPage={currentPage}
          totalPageCount={totalPages}
          onPageChange={(number) => {
            setCurrentPage(number);
            window.scrollTo(0, 0);
          }}
          sx="mt-4 sm:mt-8"
        />
      </div>
    </div>
  );
};

export default QuotationManagement;
