import React from "react";

const Heading = ({
  sx,
  size = "lg:text-2xl sm:text-xl",
  color = "white",
  bg = "primary",
  type = "h2",
  p = "2",
  ...props
}) => {
  const Component = type;

  return (
    <Component
      className={`${size} font-bold bg-${bg} p-${p} text-${color} ${
        sx ? sx : ""
      }`}
      {...props}
    />
  );
};

export default Heading;
