import React from "react";
import Contact from "../components/contact";
import Heading from "../components/heading";
import { Helmet } from "react-helmet-async";

const ContactPage = () => {
  return (
    <div className="container mx-auto p-8 sm:max-w-4xl">
      <Helmet>
        <title>AS Maskiner - Kontakt</title>
        <meta
          name="description"
          content="Kontakta AS Maskiner för frågor, support eller information om våra högkvalitativa maskiner för trä- och plastindustrin. Vi är här för att hjälpa dig med expertis och skräddarsydda lösningar. Upptäck hur vårt team kan bistå med dina behov."
        />
        <meta name="keywords" content="AS Maskiner, kontakt, support, begagnade maskiner, CNC maskiner, snickerimaskiner, industriella maskiner" />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "ContactPage",
            "url": "https://www.asmaskiner.se/contact",
            "name": "Kontakt",
            "description": "Kontakta AS Maskiner för frågor, support eller information om våra högkvalitativa maskiner för trä- och plastindustrin. Vi är här för att hjälpa dig med expertis och skräddarsydda lösningar. Upptäck hur vårt team kan bistå med dina behov."
          }`}
        </script>
      </Helmet>

      <Heading sx="mb-4 w-fit" type="h1">
        Kontakt
      </Heading>
      <Contact />
    </div>
  );
};

export default ContactPage;
