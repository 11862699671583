import HomePage from "./routes/homePage";
import Machine from "./routes/machine";
import Quotation from "./routes/quotation";
import SellMachine from "./routes/sellMachine";
import CreateMachine from "./routes/createMachine";
import Catalogue from "./routes/catalogue";
import Faq from "./routes/faq";
import ContactPage from "./routes/contactPage";
import Financing from "./routes/financing";
import AboutUs from "./routes/aboutUs";
import JobOffers from "./routes/jobOffers";
import JobDescription from "./routes/jobDescription";
import QuotationManagement from "./routes/quotationManagement";
import TermsAndConditions from "./routes/termsAndConditions";
import CreateQuotation from "./routes/createQuotation";

export default {
  "/": <HomePage />,
  "/machines/:id": <Machine />,
  "/machines/:id/quotation": <Quotation />,
  "/machines/sell": <SellMachine />,
  "/machines/create": <CreateMachine />,
  "/catalogue": <Catalogue />,
  "/faq": <Faq />,
  "/contact": <ContactPage />,
  "/financing": <Financing />,
  "/about-us": <AboutUs />,
  "/terms-and-conditions": <TermsAndConditions />,
  "/jobs": <JobOffers />,
  "/jobs/:id": <JobDescription />,
  "/sell-proposals": <QuotationManagement />,
  "/machines/:id/quotation/create": <CreateQuotation />,
};
