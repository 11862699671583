import React from "react";
import { Helmet } from "react-helmet-async";

const Financing = () => {
  return (
    <div className="container mx-auto p-8 max-w-7xl">
      <Helmet>
        <title>AS Maskiner - Finansiering</title>
      </Helmet>
    </div>
  );
};

export default Financing;
