import React, { useContext } from "react";
import { MachineContext } from "../context/MachineContext";
import { LayoutContext } from "../context/LayoutContext";
import { Link } from "react-router-dom";
import biesseLogo from "../assets/manufacturer-icons/biesse.svg";
import brandtLogo from "../assets/manufacturer-icons/brandt.svg";
import ceflaLogo from "../assets/manufacturer-icons/cefla.svg";
import formatLogo from "../assets/manufacturer-icons/format.svg";
import heesemannLogo from "../assets/manufacturer-icons/heesemann.svg";
import holzherLogo from "../assets/manufacturer-icons/holz-her.svg";
import holzmaLogo from "../assets/manufacturer-icons/holzma.svg";
import homagLogo from "../assets/manufacturer-icons/homag.svg";
import imaLogo from "../assets/manufacturer-icons/ima.svg";
import masterwoodLogo from "../assets/manufacturer-icons/masterwood.svg";
import morbidelliLogo from "../assets/manufacturer-icons/morbidelli.svg";
import scmLogo from "../assets/manufacturer-icons/scm.svg";
import weekeLogo from "../assets/manufacturer-icons/weeke.svg";
import weinigLogo from "../assets/manufacturer-icons/weinig.svg";
import Heading from "./heading";

const manufacturerLogoMap = {
  biesse: biesseLogo,
  brandt: brandtLogo,
  cefla: ceflaLogo,
  "format 4": formatLogo,
  heesemann: heesemannLogo,
  "holz-her": holzherLogo,
  holzma: holzmaLogo,
  homag: homagLogo,
  ima: imaLogo,
  masterwood: masterwoodLogo,
  morbidelli: morbidelliLogo,
  scm: scmLogo,
  weeke: weekeLogo,
  weinig: weinigLogo,
};

const Manufacturers = ({ sx }) => {
  const { machineManufacturers } = useContext(MachineContext);
  const { isMobileLayout } = useContext(LayoutContext);

  const MAX_MANUFACTURERS_TO_SHOW = isMobileLayout ? 6 : 14;

  return (
    <div className={`bg-white p-4 sm:p-16 ${sx ? sx : ""}`}>
      <div className="container mx-auto max-w-7xl">
        <div className="flex mb-6">
          <Heading>Tillverkare</Heading>
          <Link to="/catalogue" className="bg-black text-white p-1 self-start">
            Visa alla
          </Link>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-7 gap-4">
          {Object.keys(manufacturerLogoMap)
            .map((manufacturer) => {
              return (
                machineManufacturers.find(
                  (m) => m.name.toLowerCase() === manufacturer.toLowerCase()
                ) || {
                  name: manufacturer,
                  amountOfRelatedMachines: 0,
                }
              );
            })
            .sort(
              (a, b) => b.amountOfRelatedMachines - a.amountOfRelatedMachines
            )
            .slice(0, MAX_MANUFACTURERS_TO_SHOW)
            .map((manufacturer, index) => (
              <Manufacturer
                key={index}
                name={manufacturer.name}
                count={manufacturer.amountOfRelatedMachines}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const Manufacturer = ({ name, count, ...props }) => {
  return (
    <div className="flex flex-col items-center p-4 border relative" {...props}>
      {count > 0 && (
        <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-primary rounded-full h-8 w-8 flex items-center justify-center">
          <span
            className="text-white text-xs font-semibold"
            data-testid={`manufacturer-${name}-count-${count}`}
          >
            {count}
          </span>
        </div>
      )}
      <Link
        to={count > 0 ? `/catalogue?manufacturer=${name}` : "/catalogue"}
        className="flex flex-col items-center"
      >
        <div className="h-16 flex justify-center">
          <img src={manufacturerLogoMap[name.toLowerCase()]} />
        </div>
        <span className="text-sm font-medium">{name.toUpperCase()}</span>
      </Link>
    </div>
  );
};

export default Manufacturers;
