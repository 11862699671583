import React, { useContext } from "react";
import Heading from "../components/heading";
import { JobsContext } from "../context/JobsContext";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const JobOffers = () => {
  const { jobs } = useContext(JobsContext);

  return (
    <div>
      <Helmet>
        <title>AS Maskiner - Jobb</title>
        <meta
          name="description"
          content="Sök till oss på AS Maskiner. Bli del av ett team som värdesätter innovation och flexibilitet."
        />
        <meta
          name="keywords"
          content="jobb, lediga jobb, karriär, AS Maskiner, CNC maskiner, snickerimaskiner, jobbmöjligheter"
        />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "JobPosting",
            "title": "Lediga jobb på AS Maskiner",
            "description": "Sök till oss på AS Maskiner. Bli del av ett team som värdesätter innovation och flexibilitet.",
            "hiringOrganization": {
              "@type": "Organization",
              "name": "AS Maskiner",
              "sameAs": "https://www.asmaskiner.se",
              "logo": "https://www.asmaskiner.se/logo.png"
            },
            "jobLocation": {
              "@type": "Place",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Sverige",
                "addressCountry": "SE"
              }
            }
          }`}
        </script>
      </Helmet>
      <div className="bg-secondary flex flex-col items-center justify-center p-4 min-h-[20vh] text-white">
        <h2 className="text-3xl lg:text-5xl font-bold">
          AS Maskiner Sverige AB
        </h2>
        <p className="mt-4 text-sm sm:text-lg max-w-sm sm:max-w-none text-center">
          Sveriges största utbud av begagnade kvalitetsmaskiner för trä- och
          plastindustrin
        </p>
      </div>
      <div className="max-w-7xl mx-auto p-4 sm:p-12">
        <div className="flex">
          <Heading sx="mb-4" size="lg:text-4xl sm:text-xl" type="h1">
            Lediga jobb
          </Heading>
        </div>
        <JobList jobs={jobs} />
      </div>
    </div>
  );
};

const JobList = ({ jobs }) => {
  return (
    <div>
      <div className="container mx-auto">
        <div className="grid gap-4">
          {jobs.map((job, index) => (
            <Link to={`/jobs/${job.id}`} key={index}>
              <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition-shadow duration-200">
                <h2 className="text-xl font-semibold mb-2 text-primary">
                  {job.title}
                </h2>
                <p className="text-gray-800">{job.location}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JobOffers;
