import { createContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { contactInformation } from "../constants";

const JobsContext = createContext({});

const JobsContextProvider = (props) => {
  const jobs = useMemo(() => {
    return [
      {
        id: "fullstack-utvecklare-stockholm",
        title: "Fullstack utvecklare",
        location: "Stockholm",
        description: (
          <div className="[&_ul]:list-disc space-y-4 [&_li]:ml-4 [&_h3]:text-md [&_h3]:sm:text-lg">
            <strong>
              Är du redo för nästa steg i din karriär inom fullstack-utveckling?{" "}
            </strong>{" "}
            Vi söker nu en passionerad och innovativ Fullstack Utvecklare för
            att ansluta till vårt dynamiska team. Med en teknikstack som
            inkluderar Java 21 - Quarkus, PostgreSQL, Docker, och React &
            TailwindCSS, erbjuder vi en spännande möjlighet att arbeta med de
            senaste teknologierna och metoderna inom mjukvaruutveckling.
            <h3>Vad du kommer att göra</h3>
            <ul>
              <li>
                Utveckla och implementera innovativa webbapplikationer med React
                & TailwindCSS för frontend och Java 21 - Quarkus för backend.
              </li>
              <li>
                Arbeta med PostgreSQL databaser, integrationer mot diverse
                system och cloud hosting med Docker.
              </li>
              <li>
                Samarbeta i teamet för att utveckla och underhålla CI/CD
                pipelines med GitHub Actions.
              </li>
              <li>
                Bidra till alla faser i utvecklingsprocessen, från kravsamling
                till design, utveckling och deployment.
              </li>
            </ul>
            <h3>Vem du är</h3>
            <ul>
              <li>
                Erfaren i både frontend- och backend-utveckling, särskilt med
                Java, React och TailwindCSS. Bekväm med databasdesign och
                -utveckling, speciellt med PostgreSQL.
              </li>
              <li>
                Bekväm med databasdesign och -utveckling, speciellt med
                PostgreSQL.
              </li>
              <li>
                Van vid att arbeta i cloudmiljöer och med container-teknologier
                som Docker.
              </li>
              <li>
                Har god förståelse för CI/CD-processer och erfarenhet av att
                använda GitHub Actions.
              </li>
              <li>
                Självgående, nyfiken, och en lagspelare med ett starkt intresse
                för ny teknologi och mjukvaruutvecklingsmetoder.
              </li>
            </ul>
            <h3>Vi erbjuder</h3>
            <ul>
              <li>En dynamisk arbetsmiljö med senaste teknologin.</li>
              <li>Möjligheter för personlig och professionell utveckling.</li>
              <li>Ett kreativt och stöttande team.</li>
              <li>Konkurrenskraftig lön och förmåner.</li>
            </ul>
            <h4>
              <strong>Är du redo att ta dig an utmaningen? </strong> Skicka in
              din ansökan idag genom att maila{" "}
              <Link
                to={`mailto:${contactInformation.email}`}
                className="underline"
              >
                {contactInformation.email}
              </Link>
              .
            </h4>
          </div>
        ),
      },
      {
        id: "backendutvecklare-stockholm",
        title: "Backend utvecklare",
        location: "Stockholm",
        description: (
          <div className="[&_ul]:list-disc space-y-4 [&_li]:ml-4 [&_h3]:text-md [&_h3]:sm:text-lg">
            <strong>Passionerad inom backend-utveckling? </strong> Vi expanderar
            vårt team och söker en skicklig Backend Utvecklare med djupgående
            kunskaper i Java 21 - Quarkus, PostgreSQL, och erfarenhet av arbete
            med cloud hosting och CI/CD pipelines. Hos oss får du möjligheten
            att arbeta med avancerade tekniker och stora, spännande projekt.
            <h3>Vad du kommer att göra</h3>
            <ul>
              <li>
                Utveckla robust och skalbar backend-logik med Java 21 - Quarkus.
              </li>
              <li>Designa och implementera databaslösningar med PostgreSQL.</li>
              <li>
                Integrera backend-system med diverse andra system och tjänster.
              </li>
              <li>
                Arbeta med cloud hosting-lösningar och implementera
                container-teknologier som Docker.
              </li>
              <li>
                Utveckla och underhålla CI/CD pipelines med GitHub Actions för
                effektiva utvecklingsprocesser.
              </li>
            </ul>
            <h3>Vem du är</h3>
            <ul>
              <li>
                Erfaren inom backend-utveckling, speciellt med Java och Quarkus.
              </li>
              <li>
                Har god förståelse för databasutveckling, särskilt med
                PostgreSQL.
              </li>
              <li>
                Bekväm med cloudmiljöer och erfarenhet av att använda Docker.
              </li>
              <li>
                Kunnig inom CI/CD-processer och verktyg, med fokus på GitHub
                Actions.
              </li>
              <li>
                Självgående, tekniskt nyfiken och en utmärkt problemlösare.
              </li>
            </ul>
            <h3>Vi erbjuder</h3>
            <ul>
              <li>
                En roll där du kan påverka och bidra med din tekniska expertis.
              </li>
              <li>Ett öppet och inkluderande arbetsklimat.</li>
              <li>
                Stora möjligheter för personlig och professionell utveckling.
              </li>
              <li>Konkurrenskraftig lön och förmåner.</li>
            </ul>
            <h4>
              <strong>Är du redo att ta dig an utmaningen? </strong> Skicka in
              din ansökan idag genom att maila{" "}
              <Link
                to={`mailto:${contactInformation.email}`}
                className="underline"
              >
                {contactInformation.email}
              </Link>
              .
            </h4>
          </div>
        ),
      },
      {
        id: "ux-designer-stockholm",
        title: "UX designer",
        location: "Stockholm",
        description: (
          <div className="[&_ul]:list-disc space-y-4 [&_li]:ml-4 [&_h3]:text-md [&_h3]:sm:text-lg">
            <strong>Brinner du för UX-design?</strong> Vi söker nu en kreativ
            och insiktsfull UX Designer som kan hjälpa oss att skapa enastående
            användarupplevelser för våra digitala produkter. Som en del av vårt
            team kommer du att spela en avgörande roll i att definiera hur
            användare interagerar med våra produkter och tjänster.
            <h3>Vad du kommer att göra</h3>
            <ul>
              <li>
                Skapa användarcentrerade designlösningar som adresserar
                användarnas behov och affärsmål.
              </li>
              <li>
                Genomföra användarundersökningar och tester för att samla
                insikter.
              </li>
              <li>Utveckla användargränssnitt, wireframes, och prototyper.</li>
              <li>
                Arbeta tvärfunktionellt med utvecklare och produktchefer för att
                implementera designen.
              </li>
              <li>
                Ständigt utvärdera och förbättra designen baserat på
                användarfeedback och ny teknik.
              </li>
            </ul>
            <h3>Vem du är</h3>
            <ul>
              <li>
                Har erfarenhet av UX-design, från forskning till prototypning
                och användartestning.
              </li>
              <li>Kunnig i designverktyg som Figma, Sketch, eller Adobe XD.</li>
              <li>
                Har en stark portfölj som visar ditt fokus på
                användarupplevelser.
              </li>
              <li>
                Är en teamspelare som trivs i ett samarbetsinriktat miljö.
              </li>
              <li>
                Kreativ, nyfiken och ständigt söker nya sätt att förbättra
                användarupplevelsen.
              </li>
            </ul>
            <h3>Vi erbjuder</h3>
            <ul>
              <li>
                En möjlighet att forma användarupplevelsen för nya och
                befintliga produkter.
              </li>
              <li>Ett dynamiskt och kreativt arbetsklimat.</li>
              <li>Möjligheter till personlig och professionell utveckling.</li>
              <li>Konkurrenskraftig lön och förmåner.</li>
            </ul>
            <h4>
              <strong>Är du den UX Designer vi söker?</strong> Skicka in din
              ansökan idag genom att maila{" "}
              <Link
                to={`mailto:${contactInformation.email}`}
                className="underline"
              >
                {contactInformation.email}
              </Link>
              .
            </h4>
          </div>
        ),
      },
      {
        id: "app-utvecklare-stockholm",
        title: "App utvecklare",
        location: "Stockholm",
        description: (
          <div className="[&_ul]:list-disc space-y-4 [&_li]:ml-4 [&_h3]:text-md [&_h3]:sm:text-lg">
            <strong>Är du passionerad om apputveckling? </strong> Vi är på en
            spännande resa för att skapa vår första mobilapp och söker nu en
            talangfull App Utvecklare som är redo att leda vägen. Med friheten
            att välja teknologier och verktyg, erbjuder vi en unik möjlighet att
            forma och utveckla en app från grunden. Detta är din chans att sätta
            din prägel på vår digitala framtid och leda projektet mot framgång.
            <h3>Vad du kommer att göra</h3>
            <ul>
              <li>
                Ansvara för utvecklingen av vår första mobilapp, från koncept
                till lansering.
              </li>
              <li>
                Välja lämpliga teknologier och ramverk som bäst möter projektets
                behov.
              </li>
              <li>
                Designa och implementera användargränssnitt som ger en
                exceptionell användarupplevelse.
              </li>
              <li>Säkerställa appens prestanda, kvalitet och responsivitet.</li>
              <li>
                Arbeta nära med vårt UX/UI-team för att integrera design med
                teknisk funktionalitet.
              </li>
            </ul>
            <h3>Vem du är</h3>
            <ul>
              <li>
                Erfaren inom mobilapputveckling med en stark portfölj som visar
                dina färdigheter.
              </li>
              <li>
                Självgående med förmågan att välja och motivera val av
                teknologier och verktyg.
              </li>
              <li>
                Kunnig inom både iOS och Android-plattformarna, eller expertis
                inom cross-platform ramverk.
              </li>
              <li>Har ett öga för design och användarupplevelse.</li>
              <li>
                En lagspelare som trivs i en samarbetsinriktad miljö men också
                kan arbeta självständigt.
              </li>
            </ul>
            <h3>Vi erbjuder</h3>
            <ul>
              <li>
                En ledande roll i ett banbrytande projekt med stor påverkan.
              </li>
              <li>
                Möjlighet att arbeta med de senaste teknologierna och trenderna
                inom apputveckling.
              </li>
              <li>
                Stora möjligheter för personlig och professionell utveckling.
              </li>
              <li>Konkurrenskraftig lön och förmåner.</li>
            </ul>
            <h4>
              <strong>
                Är du redo att ta dig an utmaningen och forma framtiden för vår
                app?{" "}
              </strong>
              Skicka in din ansökan idag genom att maila{" "}
              <Link
                to={`mailto:${contactInformation.email}`}
                className="underline"
              >
                {contactInformation.email}
              </Link>
              .
            </h4>
          </div>
        ),
      },
      {
        id: "cnc-tekniker-stockholm",
        title: "CNC tekniker (Biesse, Homag, SCM)",
        location: "Stockholm",
        description: (
          <div className="[&_ul]:list-disc space-y-4 [&_li]:ml-4 [&_h3]:text-md [&_h3]:sm:text-lg">
            <strong>
              Söker du en roll där du kan använda din expertis inom CNC-teknik
              för att supporta toppmoderna maskiner?
            </strong>{" "}
            Vi letar efter en dedikerad CNC-tekniker specialiserad på Homag,
            Biesse, och SCM-maskiner, för att stärka vårt eftermarknadsteam.
            Denna position innebär fokus på support och underhåll av maskiner
            som redan är i drift hos våra kunder, för att säkerställa deras
            långsiktiga prestanda och tillförlitlighet. Det är en chans att
            spela en central roll i att stödja kundernas framgång och bidra till
            att de får ut mesta möjliga av sina investeringar.
            <h3>Vad du kommer att göra</h3>
            <ul>
              <li>
                Utföra service och underhåll på CNC-maskiner av märkena Homag,
                Biesse, och SCM, både på plats hos kund och på distans.
              </li>
              <li>
                Diagnostisera och åtgärda tekniska problem för att minimera
                driftstopp och förlänga maskinernas livslängd.
              </li>
              <li>
                Ge teknisk support och rådgivning till kunder för att optimera
                användningen och underhållet av deras CNC-maskiner.
              </li>
              <li>
                Uppdatera och underhålla servicejournaler och tekniska
                dokumentationer för alla genomförda arbeten.
              </li>
              <li>
                Arbeta nära med sälj- och teknikteam för att säkerställa en hög
                nivå av kundnöjdhet.
              </li>
            </ul>
            <h3>Vem du är</h3>
            <ul>
              <li>
                Har omfattande erfarenhet av service, underhåll, och support för
                CNC-maskiner, speciellt från Homag, Biesse, eller SCM.
              </li>
              <li>
                Besitter en djup teknisk förståelse för CNC-maskiners funktion
                och underhåll.
              </li>
              <li>
                Är skicklig på problemlösning och kan effektivt diagnostisera
                och åtgärda fel.
              </li>
              <li>
                Har utmärkta kommunikationsförmågor och kan erbjuda förstklassig
                kundservice.
              </li>
              <li>
                Är villig att resa till kundplatser för att utföra service och
                supportarbete.
              </li>
            </ul>
            <h3>Vi erbjuder</h3>
            <ul>
              <li>
                En viktig roll i ett företag som är ledande inom CNC-teknik och
                kundsupport.
              </li>
              <li>
                Möjligheten att arbeta med avancerade CNC-maskiner och bidra
                till våra kunders framgång.
              </li>
              <li>
                En dynamisk och stödjande arbetsmiljö där ditt arbete har direkt
                inverkan på företagets och kundernas framgång.
              </li>
              <li>
                Konkurrenskraftig lön, förmåner och möjligheter till
                professionell utveckling och vidareutbildning.
              </li>
            </ul>
            <h4>
              <strong>
                Är du redo att använda din expertis för att göra skillnad för
                våra kunder och deras CNC-maskiner?
              </strong>{" "}
              Skicka in din ansökan idag genom att maila{" "}
              <Link
                to={`mailto:${contactInformation.email}`}
                className="underline"
              >
                {contactInformation.email}
              </Link>
              .
            </h4>
          </div>
        ),
      },
    ];
  }, []);

  return (
    <JobsContext.Provider
      value={{
        jobs,
      }}
      {...props}
    />
  );
};

export { JobsContextProvider, JobsContext };
