import React, { useEffect, useState } from "react";
import { getMachines } from "../httpService";
import { buildMachineProperties } from "../machineUtils";

const MachineContext = React.createContext();

const MachineContextProvider = (props) => {
  const [machines, setMachines] = useState([]);
  const [machineCategories, setMachineCategories] = useState([]);
  const [machineManufacturers, setMachineManufacturers] = useState([]);
  const [buildYearInterval, setBuildYearInterval] = useState({});

  const initializeData = async () => {
    const machines = await getMachines();

    const { foundCategories, foundManufacturers, foundBuildYearInterval } =
      buildMachineProperties(machines);

    setMachineCategories(
      Object.keys(foundCategories).map((key) => {
        return {
          name: key,
          amountOfRelatedMachines: foundCategories[key],
        };
      })
    );

    setMachineManufacturers(
      Object.keys(foundManufacturers).map((key) => {
        return {
          name: key,
          amountOfRelatedMachines: foundManufacturers[key],
        };
      })
    );

    setMachines(machines);
    setBuildYearInterval(foundBuildYearInterval);
  };

  useEffect(() => {
    initializeData();
  }, []);

  return (
    <MachineContext.Provider
      value={{
        machines,
        machineCategories,
        machineManufacturers,
        buildYearInterval,
        reinitialize: initializeData,
      }}
      {...props}
    />
  );
};

export { MachineContextProvider, MachineContext };
