import React, { useEffect } from "react";
import Footer from "./footer";
import Header from "./header";
import {
  Outlet,
  useLocation,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CookieConsent from "./cookieConsent";
import ErrorPage from "../routes/errorPage";
import { MachineContextProvider } from "../context/MachineContext";
import { LayoutContextProvider } from "../context/LayoutContext";
import { UserContextProvider } from "../context/UserContext";
import { JobsContextProvider } from "../context/JobsContext";
import routeComponentMappings from "../routeComponentMappings";

const WrapperElement = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      // scroll to top on navigation, unless explicit hash link
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return (
    <div data-testid="wrapper-element">
      <Header />
      <div className="outlet-container">
        <Outlet />
      </div>
      <CookieConsent />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <WrapperElement />,
    children: Object.keys(routeComponentMappings).map((key) => ({
      path: key,
      element: routeComponentMappings[key],
    })),
    errorElement: <ErrorPage />,
  },
]);

export default function App() {
  return (
    <LayoutContextProvider>
      <MachineContextProvider>
        <UserContextProvider>
          <JobsContextProvider>
            <HelmetProvider>
              <Helmet>
                <meta name="og:site_name" content="AS Maskiner" />
                <meta name="og:url" content={window.location} />
                <meta name="og:locale" content="sv_SE" />
              </Helmet>
              <RouterProvider router={router} />
            </HelmetProvider>
          </JobsContextProvider>
        </UserContextProvider>
      </MachineContextProvider>
    </LayoutContextProvider>
  );
}
