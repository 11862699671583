import React from "react";

const Select = ({ error, id, label, required, options, ...props }) => {
  return (
    <>
      <label className="block text-gray-700 text-sm font-bold mb-2">
        {label} {required && <span className="text-required">*</span>}
      </label>
      <select
        {...props}
        id={id}
        className={`appearance-none border ${
          error ? "border-red-500" : "border-gray-300"
        } w-full py-2 px-3 text-gray-700 leading-tight`}
      >
        <option disabled value="">
          Välj
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.display}
          </option>
        ))}
      </select>
      {error && <span className="text-red-500 text-xs">{error}</span>}
    </>
  );
};

export default Select;
