import React, { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { MachineContext } from "../context/MachineContext";
import { createSellProposal } from "../httpService";
import Modal from "../components/modal";
import { contactInformation } from "../constants";
import Button from "../components/button";
import MachinePreview from "../components/machinePreview";
import Heading from "../components/heading";
import { Helmet } from "react-helmet-async";

const Quotation = () => {
  let { id } = useParams();
  const { machines } = useContext(MachineContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [organizationNumber, setOrganizationNumber] = useState("");
  const [comment, setComment] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [organizationNumberError, setOrganizationNumberError] = useState("");

  const [requestIsLoading, setRequestIsLoading] = useState(false);

  const validateInputs = () => {
    let isValid = true;
    if (!firstName.trim()) {
      setFirstNameError("Förnamn krävs.");
      isValid = false;
    } else {
      setFirstNameError("");
    }

    if (!lastName.trim()) {
      setLastNameError("Efternamn krävs.");
      isValid = false;
    } else {
      setLastNameError("");
    }

    if (!email.trim()) {
      setEmailError("Email krävs.");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Ogiltigt email-format.");
      isValid = false;
    } else {
      setEmailError("");
    }

    const trimmedOrganizationNumber = organizationNumber
      .trim()
      .replace("-", "");

    if (!trimmedOrganizationNumber) {
      setOrganizationNumberError("Organisationsnummer krävs.");
      isValid = false;
    } else if (
      trimmedOrganizationNumber.length !== 10 ||
      !/^\d+$/.test(trimmedOrganizationNumber)
    ) {
      setOrganizationNumberError("Ogiltigt organisationsnummer.");
      isValid = false;
    } else {
      setOrganizationNumberError("");
    }

    return isValid;
  };

  const machine = useMemo(
    () => machines.find((m) => m.id == id),
    [machines, id]
  );

  return (
    !!machine && (
      <div className="container mx-auto max-w-7xl p-8">
        <Helmet>
          <title>AS Maskiner - Begär offert</title>
          <meta
            name="description"
            content={`Få ett skräddarsytt prisförslag på ${machine.title} från AS Maskiner. Upptäck vårt engagemang för kvalitet och service. Perfekt för dina behov inom trä- och plastindustrin.`}
          />
          <meta
            name="keywords"
            content={`AS Maskiner, begagnade maskiner, ${machine.title}, begagnad ${machine.category}, ${machine.manufacturer}, offertförfrågan, träindustri, plastindustri`}
          />
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "${machine.title}",
              "image": "${machine.images[0]}",
              "description": "Få ett skräddarsytt prisförslag på ${machine.title} från AS Maskiner. Upptäck vårt engagemang för kvalitet och service. Perfekt för dina behov inom trä- och plastindustrin.",
              "brand": {
                "@type": "Brand",
                "name": "${machine.manufacturer}"
              },
              "sku": "${machine.id}",
              "category": "${machine.category}"
            }`}
          </script>
        </Helmet>
        <Heading sx="w-fit mb-4" type="h1">
          Offertförfrågan
        </Heading>
        <div className="flex flex-col lg:flex-row gap-4">
          <MachinePreview
            machine={machine}
            sx="w-full lg:max-w-sm lg:order-2"
            minimal={true}
          />
          <div className="w-full lg:w-2/3 border lg:order-1">
            <div className="p-8">
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="mb-6 md:w-1/2">
                  <label
                    htmlFor="firstname"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Förnamn <span className="text-required">*</span>
                  </label>
                  <input
                    type="text"
                    id="firstname"
                    data-testid="firstname-input"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    className={`appearance-none border ${
                      firstNameError ? "border-red-500" : "border-gray-300"
                    } w-full py-2 px-3 text-gray-700 leading-tight`}
                  />
                  {firstNameError && (
                    <span className="text-red-500 text-xs">
                      {firstNameError}
                    </span>
                  )}
                </div>
                <div className="mb-6 md:w-1/2">
                  <label
                    htmlFor="lastname"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Efternamn <span className="text-required">*</span>
                  </label>
                  <input
                    type="text"
                    id="lastname"
                    data-testid="lastname-input"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    className={`appearance-none border ${
                      lastNameError ? "border-red-500" : "border-gray-300"
                    } w-full py-2 px-3 text-gray-700 leading-tight`}
                  />
                  {lastNameError && (
                    <span className="text-red-500 text-xs">
                      {lastNameError}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="mb-6 md:w-1/2">
                  <label
                    htmlFor="telephone"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Telefonnummer
                  </label>
                  <input
                    type="tel"
                    id="telephone"
                    value={telephone}
                    onChange={(event) => setTelephone(event.target.value)}
                    className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
                  />
                </div>
                <div className="mb-6 md:w-1/2">
                  <label
                    htmlFor="email"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Email <span className="text-required">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    data-testid="email-input"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    className={`appearance-none border ${
                      emailError ? "border-red-500" : "border-gray-300"
                    } w-full py-2 px-3 text-gray-700 leading-tight`}
                  />
                  {emailError && (
                    <span className="text-red-500 text-xs">{emailError}</span>
                  )}
                </div>
              </div>

              <div className="flex flex-col">
                <div className="md:w-1/2 md:pr-2">
                  <label
                    htmlFor="organizationNumber"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Organisationsnummer <span className="text-required">*</span>
                  </label>
                  <input
                    type="text"
                    id="organizationNumber"
                    data-testid="organizationNumber-input"
                    value={organizationNumber}
                    onChange={(event) =>
                      setOrganizationNumber(event.target.value)
                    }
                    className={`appearance-none border ${
                      organizationNumberError
                        ? "border-red-500"
                        : "border-gray-300"
                    } w-full py-2 px-3 text-gray-700 leading-tight`}
                  />
                  {organizationNumberError && (
                    <span className="text-red-500 text-xs">
                      {organizationNumberError}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <hr />

            <div className="px-8 pt-8 pb-6">
              <label
                htmlFor="comment"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Kommentar
              </label>
              <textarea
                id="comment"
                rows="4"
                value={comment}
                onChange={(event) => setComment(event.target.value)}
                className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
              />
            </div>
          </div>
        </div>
        <Button
          loading={requestIsLoading}
          data-testid="send-quotation-request-button"
          onClick={() => {
            if (validateInputs()) {
              setRequestIsLoading(true);
              createSellProposal({
                machineId: machine.id,
                firstName,
                lastName,
                phoneNumber: telephone,
                email,
                comment,
                organizationNumber,
              })
                .then((res) => {
                  setApiResponse({
                    successful: true,
                    message:
                      "Din offertförfrågan är mottagen. Vi kontaktar dig så snart som möjligt.",
                  });
                  setIsModalOpen(true);
                  dataLayer.push({
                    event: "quotation_created",
                  });
                })
                .catch((err) => {
                  setApiResponse({
                    successful: false,
                    message:
                      err.status === 409
                        ? "Du har redan en pågående offertförfrågan för den här maskinen."
                        : `Kunde inte skapa offertförfrågan. Vänligen kontakta oss istället på ${contactInformation.telephoneNumber}.`,
                  });
                  dataLayer.push({
                    event: "quotation_creation_failed",
                  });
                  setIsModalOpen(true);
                })
                .finally(() => setRequestIsLoading(false));
            }
          }}
          sx="mt-4"
        >
          Skicka förfrågan
        </Button>
        {isModalOpen && !!apiResponse && (
          <Modal
            header={
              apiResponse.successful
                ? "Offertförfrågan skapad"
                : "Offertförfrågan misslyckades"
            }
            onClose={() => {
              if (apiResponse.successful) {
                setFirstName("");
                setLastName("");
                setTelephone("");
                setEmail("");
                setComment("");
                setOrganizationNumber("");
              }

              setIsModalOpen(false);
              setApiResponse(null);
            }}
          >
            <p>{apiResponse.message}</p>
          </Modal>
        )}
      </div>
    )
  );
};

export default Quotation;
