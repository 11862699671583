import React, { createContext, useState, useEffect, useCallback } from "react";

const LayoutContext = createContext({});

export const constants = {
  mobile: "mobile",
  tablet: "tablet",
  desktop: "desktop",
};

const getLayoutMode = (currentWidth) => {
  if (currentWidth <= 767 || isMobileLandscapeMode()) {
    return constants.mobile;
  }
  if (currentWidth <= 1024) {
    return constants.tablet;
  }
  return constants.desktop;
};

const isMobileLandscapeMode = () => {
  return (
    window.matchMedia("(orientation: landscape)").matches &&
    window.innerHeight < 420
  );
};

const LayoutContextProvider = (props) => {
  const [layoutMode, setLayoutMode] = useState(
    getLayoutMode(window.innerWidth)
  );
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setLayoutMode(getLayoutMode(width));
  }, [width]);

  const isMobileLayout = layoutMode === constants.mobile;
  const isTabletLayout = layoutMode === constants.tablet;
  const isDesktopLayout = layoutMode === constants.desktop;

  return (
    <LayoutContext.Provider
      value={{
        isMobileLayout,
        isTabletLayout,
        isDesktopLayout,
      }}
      {...props}
    />
  );
};

export { LayoutContextProvider, LayoutContext };
