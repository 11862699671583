import React, { useState } from "react";
import { contact } from "../httpService";
import Modal from "./modal";
import { contactInformation } from "../constants";
import Button from "./button";

const Contact = ({ sendButtonText = "Kontakta oss" }) => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [apiResponse, setApiResponse] = useState(null);

  const [emailError, setEmailError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [textError, setTextError] = useState("");

  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const validateInputs = () => {
    let isValid = true;

    if (!email.trim()) {
      setEmailError("Email krävs.");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Ogiltigt email-format.");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!subject.trim()) {
      setSubjectError("Ämne krävs.");
      isValid = false;
    } else {
      setSubjectError("");
    }

    if (!text.trim()) {
      setTextError("Meddelande krävs.");
      isValid = false;
    } else {
      setTextError("");
    }

    return isValid;
  };

  return (
    <>
      <div className="border">
        <div className="p-8">
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="mb-6 md:w-1/2">
              <label
                htmlFor="subject"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Ämne <span className="text-required">*</span>
              </label>
              <input
                type="text"
                id="subject"
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
                className={`appearance-none border ${
                  subjectError ? "border-red-500" : "border-gray-300"
                } w-full py-2 px-3 text-gray-700 leading-tight`}
              />
              {subjectError && (
                <span className="text-red-500 text-xs">{subjectError}</span>
              )}
            </div>
            <div className="mb-6 md:w-1/2">
              <label
                htmlFor="email"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Email <span className="text-required">*</span>
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                className={`appearance-none border ${
                  emailError ? "border-red-500" : "border-gray-300"
                } w-full py-2 px-3 text-gray-700 leading-tight`}
              />
              {emailError && (
                <span className="text-red-500 text-xs">{emailError}</span>
              )}
            </div>
          </div>
        </div>

        <hr />

        <div className="px-8 pt-8 pb-6">
          <label
            htmlFor="text"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Meddelande <span className="text-required">*</span>
          </label>
          <textarea
            id="text"
            rows="4"
            value={text}
            onChange={(event) => setText(event.target.value)}
            className={`appearance-none border ${
              subjectError ? "border-red-500" : "border-gray-300"
            } w-full py-2 px-3 text-gray-700 leading-tight`}
          />
          {textError && (
            <span className="text-red-500 text-xs">{textError}</span>
          )}
        </div>
      </div>
      <Button
        loading={requestIsLoading}
        onClick={() => {
          if (validateInputs()) {
            setRequestIsLoading(true);
            contact({
              subject,
              text,
              replyTo: email,
            })
              .then((res) => {
                setApiResponse({
                  successful: true,
                  message:
                    "Tack för ditt meddelande. Vi återkommer så snart som möjligt.",
                });
                setIsModalOpen(true);
              })
              .catch((err) => {
                setApiResponse({
                  successful: false,
                  message: `Vi kunde inte ta emot ditt meddelande. Vänligen ring oss på ${contactInformation.telephoneNumber} istället.`,
                });
                setIsModalOpen(true);
              })
              .finally(() => setRequestIsLoading(false));
          }
        }}
        sx="mt-4"
      >
        {sendButtonText}
      </Button>
      {isModalOpen && !!apiResponse && (
        <Modal
          header={
            apiResponse.successful
              ? "Meddelande mottaget"
              : "Meddelande misslyckades"
          }
          onClose={() => {
            if (apiResponse.successful) {
              setEmail("");
              setSubject("");
              setText("");
            }

            setIsModalOpen(false);
            setApiResponse(null);
          }}
        >
          <p>{apiResponse.message}</p>
        </Modal>
      )}
    </>
  );
};

export default Contact;
