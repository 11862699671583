import React from "react";
import { contactInformation } from "../constants";
import { Link } from "react-router-dom";
import Heading from "../components/heading";
import { Helmet } from "react-helmet-async";

const PhoneNumber = () => (
  <Link to={`tel:${contactInformation.telephoneNumber}`} className="underline">
    {contactInformation.telephoneNumber}
  </Link>
);

const Email = () => (
  <Link to={`mailto:${contactInformation.email}`} className="underline">
    {contactInformation.email}
  </Link>
);

const faq = [
  {
    question: "Vad är www.asmaskiner.se?",
    answer: (
      <p>
        AS Maskiner har Sveriges största utbud av begagnade maskiner för trä-/
        och plastindustrin med fabrikat som SCM, Homag, Biesse, Felder,
        Masterwood, Holzher, Weinig, Altendorf, Martin, Elcon, Striebig och IMA
        med flera fabrikat.
      </p>
    ),
  },
  {
    question: "Hur söker jag efter intressanta maskiner?",
    answer: (
      <ul>
        <li>
          Alternativ 1: Använd sökfältet högst upp på vår hemsida som en
          Google-sökning. Skriv in namnet på den maskin du söker efter och
          klicka sedan på den maskin du är intresserad av.
        </li>
        <li>
          Alternativ 2: Du klickar på den maskinikon som du söker efter och då
          får du upp samtliga maskinfabrikat i den maskinkategorin.
        </li>
        <li>
          Alternativ 3: Om du söker ett specifikt fabrikat kan du gå ner längst
          ner på vår hemsida och klicka på fabrikatets logga och då kommer du
          till samtliga maskiner inom de fabrikatet som vi erbjuder.
        </li>
      </ul>
    ),
  },
  {
    question: "Hur kontaktar jag en säljare?",
    answer: (
      <>
        <div>
          Ni når vår försäljningsavdelning på telefon och mejl mellan kl: 07-20.
        </div>
        <div>
          Mejladress: <Email />
        </div>
        <div>
          Telefonnummer: <PhoneNumber />
        </div>
      </>
    ),
  },
  {
    question: "Hur går jag till väga om jag vill köpa en maskin?",
    answer: (
      <>
        <div>Kontakta vår försäljningsavdelning:</div>
        <div>
          Telefonnummer: <PhoneNumber />
        </div>
        <div>
          Mejladress: <Email />
        </div>
      </>
    ),
  },
  {
    question: "Hur går jag till väga för att sälja min maskin via AS Maskiner?",
    answer: (
      <div>
        Kontakta oss på telefonnummer <PhoneNumber /> alternativt skicka ett
        mejl till <Email />.
      </div>
    ),
  },
  {
    question: "Erbjuder ni leasing av maskiner som man köper av er?",
    answer: (
      <div>
        Vi erbjuder leasing via DNB Bank där vi under en längre tid har
        samarbetat och dem förstår våra produkter som vi säljer. Är du
        intresserad av att veta vad en specifika maskin skulle kosta att ha på
        leasing så klickar du{" "}
        <Link to="/financing" className="underline">
          här
        </Link>{" "}
        och skriver sedan in det belopp som maskinen kostar.
      </div>
    ),
  },
  {
    question: "Betalning",
    answer: (
      <ul>
        <li>
          För att vi skall lägga undan en maskin skall 30 % av beloppet vara
          betalat inom tre bankdagar, om inte annat är avtalat med säljare.
        </li>
        <li>
          Innan vi nedmonterar en maskin skall 100 % av maskinens belopp
          betalas.
        </li>
        <li>
          Vi skickar inga maskiner innan vi har erhållit en fullständig
          betalning från köparen.
        </li>
      </ul>
    ),
  },
  {
    question: "Frakt av maskiner",
    answer: (
      <p>
        Vi samarbetar med Conroute och vid köp av maskiner så kan vi även hjälpa
        er att ta fram ett fraktförslag till er.
      </p>
    ),
  },
];

const Faq = () => {
  return (
    <div className="container mx-auto max-w-7xl p-8">
      <Helmet>
        <title>AS Maskiner - Vanliga frågor</title>
        <meta
          name="description"
          content="Få svar på dina vanligaste frågor om AS Maskiners produkter och tjänster på vår FAQ-sida. Från beställningsprocessen till produktstöd, vi täcker allt för att göra din upplevelse enklare."
        />
        <meta
          name="keywords"
          content="AS Maskiner, begagnade maskiner, begagnad snickerimaskin, CNC fräs, Homag CNC, Biesse CNC, SCM CNC, Morbidelli CNC, begagnad CNC, begagnad plattuppdelningssåg, begagnad bredbandputs, begagnad kantlistmaskin, begagnad listhyvel, begagnad hyvel, begagnad justersåg, begagnad spånsug, begagnad väggsåg, begagnad fanérpress"
        />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "url": "https://www.asmaskiner.se/faq",
            "name": "Vanliga frågor",
            "description": "Få svar på dina vanligaste frågor om AS Maskiners produkter och tjänster på vår FAQ-sida. Från beställningsprocessen till produktstöd, vi täcker allt för att göra din upplevelse enklare.",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "Vad är www.asmaskiner.se?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "AS Maskiner har Sveriges största utbud av begagnade maskiner för trä-/ och plastindustrin med fabrikat som SCM, Homag, Biesse, Felder, Masterwood, Holzher, Weinig, Altendorf, Martin, Elcon, Striebig och IMA med flera fabrikat."
                }
              },
              {
                "@type": "Question",
                "name": "Hur söker jag efter intressanta maskiner?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Alternativ 1: Använd sökfältet högst upp på vår hemsida som en Google-sökning. Skriv in namnet på den maskin du söker efter och klicka sedan på den maskin du är intresserad av. Alternativ 2: Du klickar på den maskinikon som du söker efter och då får du upp samtliga maskinfabrikat i den maskinkategorin. Alternativ 3: Om du söker ett specifikt fabrikat kan du gå ner längst ner på vår hemsida och klicka på fabrikatets logga och då kommer du till samtliga maskiner inom de fabrikatet som vi erbjuder."
                }
              },
              {
                "@type": "Question",
                "name": "Hur kontaktar jag en säljare?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Ni når vår försäljningsavdelning på telefon och mejl mellan kl: 07-20. Mejladress: ${contactInformation.email} Telefonnummer: ${contactInformation.telephoneNumber}"
                }
              },
              {
                "@type": "Question",
                "name": "Hur går jag till väga om jag vill köpa en maskin?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Kontakta vår försäljningsavdelning: Telefonnummer: ${contactInformation.telephoneNumber} Mejladress: ${contactInformation.email}"
                }
              },
              {
                "@type": "Question",
                "name": "Hur går jag till väga för att sälja min maskin via AS Maskiner?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Kontakta oss på telefonnummer ${contactInformation.telephoneNumber} alternativt skicka ett mejl till ${contactInformation.email}."
                }
              },
              {
                "@type": "Question",
                "name": "Erbjuder ni leasing av maskiner som man köper av er?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Vi erbjuder leasing via DNB Bank där vi under en längre tid har samarbetat och dem förstår våra produkter som vi säljer. Är du intresserad av att veta vad en specifika maskin skulle kosta att ha på leasing så klickar du här och skriver sedan in det belopp som maskinen kostar."
                }
              },
              {
                "@type": "Question",
                "name": "Betalning",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "För att vi skall lägga undan en maskin skall 30 % av beloppet vara betalat inom tre bankdagar, om inte annat är avtalat med säljare. Innan vi nedmonterar en maskin skall 100 % av maskinens belopp betalas. Vi skickar inga maskiner innan vi har erhållit en fullständig betalning från köparen."
                }
              },
              {
                "@type": "Question",
                "name": "Frakt av maskiner",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Vi samarbetar med Conroute och vid köp av maskiner så kan vi även hjälpa er att ta fram ett fraktförslag till er."
                }
              }
            ]
          }`}
        </script>
      </Helmet>

      <Heading sx="mb-8 w-fit" type="h1">
        Vanliga frågor
      </Heading>

      <div className="flex flex-col gap-8">
        {faq.map((v, index) => (
          <Question key={index} question={v.question} answer={v.answer} />
        ))}
      </div>
    </div>
  );
};

function Question({ question, answer }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
      <h3 className="text-xl font-semibold mb-2 text-primary">{question}</h3>
      <div className="text-gray-800">{answer}</div>
    </div>
  );
}

export default Faq;
