import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { MachineContext } from "../context/MachineContext";
import logoUrl from "../assets/logo.png";
import wasaKreditLogoUrl from "../assets/logo-wasa-kredit.png";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import Markdown from "react-markdown";
import { contactInformation } from "../constants";
import Modal from "../components/modal";
import Button from "../components/button";
import { getMonthlyLeasingCost } from "../leasingUtils";
import { termsAndConditions } from "../termsAndConditions";

const styles = StyleSheet.create({
  page: {
    padding: 40,
  },
  header: {
    flexDirection: "row",
    width: "100%",
    borderColor: "black",
    borderBottom: "1",
    borderStyle: "solid",
  },
  logo: {
    height: "12mm",
    width: "50mm",
  },
  logoWasaKredit: {
    height: "12mm",
    width: "50mm",
    marginTop: "5mm",
    marginBottom: "5mm",
  },
  logoSection: {
    flexGrow: 1,
  },
  customerText: {
    fontSize: "12",
    marginBottom: "1mm",
  },
  organisationNumberText: {
    fontSize: "11",
    color: "grey",
    marginBottom: "4mm",
  },
  locationAndDateSection: {
    marginTop: "4mm",
    fontSize: "12",
    fontFamily: "Helvetica-Bold",
  },
  referencesSection: {
    marginTop: "6mm",
    fontSize: "11",
  },
  referenceSection: {
    flexDirection: "row",
    marginTop: "4mm",
  },
  referenceKey: {
    width: "30mm",
  },
  introductionSection: {
    fontSize: "11",
    marginTop: "10mm",
    marginBottom: "4mm",
  },
  agreementsSection: {
    fontSize: "11",
  },
  agreementSection: {
    flexDirection: "row",
    marginTop: "6mm",
  },
  agreementKey: {
    fontFamily: "Helvetica-Bold",
    width: "50mm",
  },
  outroSection: {
    marginTop: "30mm",
    fontSize: "11",
  },
  price: {
    marginTop: "5mm",
    padding: 1,
    fontSize: "12",
    backgroundColor: "#d3d3d3",
    flexDirection: "row",
  },
  priceMachineTitle: {
    flexGrow: 1,
  },
  machinePrice: {
    color: "red",
  },
  machineImagesSection: {
    marginTop: "4mm",
  },
  primaryMachineImage: {
    width: "100%",
    maxHeight: "150mm",
  },
  subMachineImages: {
    marginTop: "5mm",
    flexDirection: "row",
    width: "100%",
    height: "50mm",
  },
  subMachineImage2: {
    marginLeft: "5mm",
  },
  technicalDataSectionWrapper: {
    marginTop: "4mm",
    fontSize: "12",
  },
  technicalDataSection: {
    marginTop: "4mm",
    fontSize: "12",
    borderColor: "black",
    borderLeft: "1",
    borderRight: "1",
    borderTop: "1",
    borderStyle: "solid",
  },
  technicalData: {
    flexDirection: "row",
  },
  technicalDataKey: {
    width: "50%",
    borderColor: "black",
    borderRight: "1",
    borderBottom: "1",
    borderStyle: "solid",
    padding: 2,
  },
  technicalDataValue: {
    width: "50%",
    borderColor: "black",
    borderBottom: "1",
    borderStyle: "solid",
    padding: 2,
  },
  wasaKreditSection: {
    fontSize: "11",
    fontFamily: "Helvetica-Bold",
    marginTop: "3mm",
    borderColor: "black",
    border: "1",
    borderStyle: "solid",
    padding: 2,
    width: "100mm",
  },
  additionalInformationHeader: {
    fontFamily: "Helvetica-Bold",
    marginBottom: "1.5mm",
  },
});

const CreateQuotation = () => {
  let { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isAdmin, initialized, getJwt } = useContext(UserContext);
  const { machines } = useContext(MachineContext);
  const [informationToUse, setInformationToUse] = useState(null);

  const machine = machines.find((m) => m.id == id);

  useEffect(() => {
    if (initialized && !isAdmin) {
      navigate("/");
    }
  }, [isAdmin, initialized]);

  return informationToUse ? (
    <div className="w-full h-screen">
      <PDFViewer className="w-full h-screen">
        <Document className="w-full h-screen">
          <Page size="A4" style={styles.page}>
            <View style={styles.header}>
              <View style={styles.logoSection}>
                <Image src={logoUrl} alt="AS Maskiner" style={styles.logo} />
              </View>
              <View>
                <Text style={styles.customerText}>
                  Offertnummer: {informationToUse.quotationNumber}
                </Text>
                <Text style={styles.customerText}>
                  {informationToUse.companyName}
                </Text>
                <Text style={styles.organisationNumberText}>
                  {informationToUse.organisationNumber}
                </Text>
              </View>
            </View>
            <View style={styles.locationAndDateSection}>
              <Text>
                Stockholm,{" "}
                {new Date().toLocaleDateString("sv-SE", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </Text>
            </View>
            <View style={styles.referencesSection}>
              <View style={styles.referenceSection}>
                <Text style={styles.referenceKey}>Vår ref.</Text>
                <Text>{informationToUse.ourReference}</Text>
              </View>
              <View style={styles.referenceSection}>
                <Text style={styles.referenceKey}>Tel.</Text>
                <Text>{informationToUse.ourReferenceTelephone}</Text>
              </View>
              <View style={styles.referenceSection}>
                <Text style={styles.referenceKey}>Er ref.</Text>
                <Text>{informationToUse.companyReference}</Text>
              </View>
              <View style={styles.referenceSection}>
                <Text style={styles.referenceKey}>Tel.</Text>
                <Text>{informationToUse.companyReferenceTelephone}</Text>
              </View>
            </View>
            <View style={styles.introductionSection}>
              <Text>
                Vi tackar för Er förfrågan och har härmed nöjet att offerera{" "}
                {machine.title}, på nedanstående villkor och enligt ALLMÄNNA
                LEVERANSBESTÄMMELSER NL 09 undantaget § 13 och 14.
              </Text>
            </View>
            <View style={styles.agreementsSection}>
              <View style={styles.agreementSection}>
                <Text style={styles.agreementKey}>Leveransvillkor:</Text>
                <Text>{informationToUse.deliveryTerms}</Text>
              </View>
              <View style={styles.agreementSection}>
                <Text style={styles.agreementKey}>Leveranstid:</Text>
                <Text>{informationToUse.deliveryTime}</Text>
              </View>
              <View style={styles.agreementSection}>
                <Text style={styles.agreementKey}>Betalningsvillkor:</Text>
                <Text>{informationToUse.paymentTerms}</Text>
              </View>
              <View style={styles.agreementSection}>
                <Text style={styles.agreementKey}>Kursklausul:</Text>
                <Text>{informationToUse.courseClause}</Text>
              </View>
              <View style={styles.agreementSection}>
                <Text style={styles.agreementKey}>Giltighetstid:</Text>
                <Text>{informationToUse.validTime}</Text>
              </View>
              <View style={styles.outroSection}>
                <Text>
                  Vi står gärna till tjänst med ytterligare upplysningar och ser
                  fram emot att höra ifrån Er.
                </Text>
              </View>
            </View>
          </Page>
          <Page size="A4" style={styles.page}>
            <View style={styles.header}>
              <View style={styles.logoSection}>
                <Image src={logoUrl} alt="AS Maskiner" style={styles.logo} />
              </View>
              <View>
                <Text style={styles.customerText}>
                  Offertnummer: {informationToUse.quotationNumber}
                </Text>
                <Text style={styles.customerText}>
                  {informationToUse.companyName}
                </Text>
                <Text style={styles.organisationNumberText}>
                  {informationToUse.organisationNumber}
                </Text>
              </View>
            </View>
            <View style={styles.price}>
              <Text style={styles.priceMachineTitle}>{machine.title}</Text>
              <Text>Pris: </Text>
              <Text style={styles.machinePrice}>
                {formattedPrice(informationToUse.priceInSek)}
              </Text>
            </View>
            {informationToUse.newPriceInSek && (
              <View style={{ flexDirection: "row", marginTop: "0.5mm" }}>
                <Text style={{ flexGrow: 1 }} />
                <Text
                  style={{
                    fontSize: "12",
                    fontFamily: "Times-Italic",
                    marginRight: "0.5mm",
                  }}
                >
                  Nypris: {formattedPrice(informationToUse.newPriceInSek)}
                </Text>
              </View>
            )}
            {informationToUse.descriptiveText && (
              <View style={{ marginTop: "4mm", fontSize: "11" }}>
                <Text>{informationToUse.descriptiveText}</Text>
              </View>
            )}
            <View style={styles.machineImagesSection}>
              <Image
                style={styles.primaryMachineImage}
                src={{
                  uri: `/api/admin/proxy/image?imageUrl=${machine.images[0]}`,
                  method: "GET",
                  headers: {
                    Authorization: getJwt(),
                  },
                }}
              />
              {machine.images.length > 2 && (
                <View style={styles.subMachineImages}>
                  <Image
                    src={{
                      uri: `/api/admin/proxy/image?imageUrl=${machine.images[1]}`,
                      method: "GET",
                      headers: {
                        Authorization: getJwt(),
                      },
                    }}
                  />
                  <Image
                    style={styles.subMachineImage2}
                    src={{
                      uri: `/api/admin/proxy/image?imageUrl=${machine.images[2]}`,
                      method: "GET",
                      headers: {
                        Authorization: getJwt(),
                      },
                    }}
                  />
                </View>
              )}
            </View>
          </Page>
          <Page size="A4" style={styles.page}>
            <View style={styles.header}>
              <View style={styles.logoSection}>
                <Image src={logoUrl} alt="AS Maskiner" style={styles.logo} />
              </View>
              <View>
                <Text style={styles.customerText}>
                  Offertnummer: {informationToUse.quotationNumber}
                </Text>
                <Text style={styles.customerText}>
                  {informationToUse.companyName}
                </Text>
                <Text style={styles.organisationNumberText}>
                  {informationToUse.organisationNumber}
                </Text>
              </View>
            </View>
            <View style={styles.technicalDataSectionWrapper}>
              <Text>Teknisk data:</Text>
              <View style={styles.technicalDataSection}>
                {informationToUse.technicalData.map((attribute, index) => (
                  <View style={styles.technicalData} key={index}>
                    <Text style={styles.technicalDataKey}>{attribute.key}</Text>
                    <Text style={styles.technicalDataValue}>
                      {attribute.value}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
            {machine.description && (
              <View style={{ marginTop: "4mm", fontSize: "11" }}>
                <Text style={{ marginBottom: "2mm", fontSize: "12" }}>
                  Ytterligare information:
                </Text>
                <Markdown
                  components={{
                    p: ({ children }) => (
                      <Text style={{ marginBottom: "1mm" }}>{children}</Text>
                    ),
                    h1: ({ children }) => (
                      <Text style={styles.additionalInformationHeader}>
                        {children}
                      </Text>
                    ),
                    h2: ({ children }) => (
                      <Text style={styles.additionalInformationHeader}>
                        {children}
                      </Text>
                    ),
                    h3: ({ children }) => (
                      <Text style={styles.additionalInformationHeader}>
                        {children}
                      </Text>
                    ),
                    h4: ({ children }) => (
                      <Text style={styles.additionalInformationHeader}>
                        {children}
                      </Text>
                    ),
                  }}
                >
                  {machine.description}
                </Markdown>
              </View>
            )}
            {machine.images.length > 4 && (
              <View
                style={{
                  flexWrap: "wrap",
                  marginTop: "5mm",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                {machine.images.slice(3, 9).map((image, index) => (
                  <Image
                    key={index}
                    style={{ flexBasis: "33%", marginBottom: "5mm" }}
                    src={{
                      uri: `/api/admin/proxy/image?imageUrl=${image}`,
                      method: "GET",
                      headers: {
                        Authorization: getJwt(),
                      },
                    }}
                  />
                ))}
              </View>
            )}
          </Page>
          <Page size="A4" style={styles.page}>
            <View style={styles.header}>
              <View style={styles.logoSection}>
                <Image src={logoUrl} alt="AS Maskiner" style={styles.logo} />
              </View>
              <View>
                <Text style={styles.customerText}>
                  Offertnummer: {informationToUse.quotationNumber}
                </Text>
                <Text style={styles.customerText}>
                  {informationToUse.companyName}
                </Text>
                <Text style={styles.organisationNumberText}>
                  {informationToUse.organisationNumber}
                </Text>
              </View>
            </View>
            <Image src={wasaKreditLogoUrl} style={styles.logoWasaKredit} />
            <View style={styles.wasaKreditSection}>
              <Text style={{ marginBottom: "5mm" }}>
                20% kontant betalning (
                {formattedPrice(informationToUse.priceInSek * 0.2)} + moms):
              </Text>
              {[36, 48, 60, 72, 84].map((monthsOfLeasing) => (
                <Text key={monthsOfLeasing}>
                  Leasing {monthsOfLeasing} månader:{" "}
                  {formattedPrice(
                    getMonthlyLeasingCost(
                      monthsOfLeasing,
                      informationToUse.priceInSek * 0.8
                    )
                  )}{" "}
                  / månad + moms
                </Text>
              ))}
              <Text style={{ marginTop: "3mm" }}>
                Restvärde:{" "}
                {formattedPrice(informationToUse.priceInSek * 0.8 * 0.1)}
              </Text>
            </View>
            <View style={styles.wasaKreditSection}>
              <Text style={{ marginBottom: "5mm" }}>Inget kontant:</Text>
              {[36, 48, 60, 72, 84].map((monthsOfLeasing) => (
                <Text key={monthsOfLeasing}>
                  Leasing {monthsOfLeasing} månader:{" "}
                  {formattedPrice(
                    getMonthlyLeasingCost(
                      monthsOfLeasing,
                      informationToUse.priceInSek
                    )
                  )}{" "}
                  / månad + moms
                </Text>
              ))}
              <Text style={{ marginTop: "3mm" }}>
                Restvärde: {formattedPrice(informationToUse.priceInSek * 0.1)}
              </Text>
            </View>
            <View style={{ marginTop: "5mm" }}>
              <Text style={{ fontSize: "12", fontFamily: "Helvetica-Bold" }}>
                Pris: {formattedPrice(informationToUse.priceInSek)}
              </Text>
            </View>
            <View style={{ fontSize: "12", marginTop: "10mm" }}>
              <Text>Frakt: {informationToUse.transportPrice}</Text>
              <Text>Montage: {informationToUse.assemblyPrice}</Text>
              <Text>Utbildning: {informationToUse.educationPrice}</Text>
              <Text>Priser exkl. moms</Text>
            </View>
            <View style={{ fontSize: "12", marginTop: "8mm" }}>
              <Text>AS Maskiner Sverige AB</Text>
              <Text>{informationToUse.ourReference}</Text>
              <Text>{informationToUse.ourReferenceTelephone}</Text>
            </View>
            <View
              style={{
                fontSize: "9",
                width: "100%",
                borderColor: "black",
                borderTop: "1",
                borderStyle: "solid",
                flexGrow: 1,
                marginTop: "65mm",
              }}
            >
              <Text style={{ textAlign: "center", marginTop: "3mm" }}>
                AS Maskiner Sverige AB - Humlegårdsgatan 19 b, 114 46 Stockholm
                - Tel. {informationToUse.ourReferenceTelephone}
              </Text>
              <Text style={{ textAlign: "center", marginTop: "1mm" }}>
                Org nr: 559316-4824 - F-skattebevis finnes - Styrelsens säte:
                Stockholm
              </Text>
            </View>
          </Page>
          <Page size="A4" style={styles.page}>
            <View style={styles.header}>
              <View style={styles.logoSection}>
                <Image src={logoUrl} alt="AS Maskiner" style={styles.logo} />
              </View>
              <View>
                <Text style={styles.customerText}>
                  Offertnummer: {informationToUse.quotationNumber}
                </Text>
                <Text style={styles.customerText}>
                  {informationToUse.companyName}
                </Text>
                <Text style={styles.organisationNumberText}>
                  {informationToUse.organisationNumber}
                </Text>
              </View>
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "Helvetica-Bold",
                  fontSize: 12,
                  marginTop: "5mm",
                }}
              >
                Allmänna villkor
              </Text>
              {termsAndConditions.map((section, index) => {
                return (
                  <View style={{ marginTop: "3mm" }} key={index}>
                    <Text
                      style={{
                        fontFamily: "Helvetica-Bold",
                        fontSize: 10,
                        marginBottom: "1mm",
                      }}
                    >
                      {section.title}
                    </Text>
                    {section.points.map((point, pointIndex) => {
                      return (
                        <Text style={{ fontSize: 9 }} key={pointIndex}>
                          {String.fromCharCode(97 + pointIndex)}) {point}
                        </Text>
                      );
                    })}
                  </View>
                );
              })}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  ) : (
    machine && (
      <ConfirmInformationModal
        organisationNumber={searchParams.get("organisationNumber")}
        companyReference={searchParams.get("companyReference")}
        companyReferenceTelephone={searchParams.get(
          "companyReferenceTelephone"
        )}
        quotationNumber={
          searchParams.get("sellProposalId")
            ? 78000 + parseInt(searchParams.get("sellProposalId"))
            : 0
        }
        technicalData={[
          {
            key: "Årsmodell",
            value: machine.buildYear,
          },
        ].concat(machine.attributes)}
        onComplete={setInformationToUse}
      />
    )
  );
};

const ConfirmInformationModal = ({
  organisationNumber,
  companyName,
  companyReference,
  companyReferenceTelephone,
  onComplete,
  quotationNumber,
  technicalData,
}) => {
  const [informationToUse, setInformationToUse] = useState({
    organisationNumber: organisationNumber ? organisationNumber : "",
    companyName: companyName ? companyName : "",
    ourReference: "Niklas Sernbrandt",
    ourReferenceTelephone: contactInformation.telephoneNumber,
    companyReference: companyReference ? companyReference : "",
    companyReferenceTelephone: companyReferenceTelephone
      ? companyReferenceTelephone
      : "",
    priceInSek: "",
    newPriceInSek: "",
    deliveryTime: "",
    paymentTerms:
      "60 % vid order, 30 % innan leverans och 10 % efter igångkörning.",
    courseClause: "Fast pris.",
    validTime:
      "Offerten gäller 30 dagar. (så länge maskin ej säljs till annan kund).",
    deliveryTerms: "Frakt inkluderat i pris (ej avlastning).",
    quotationNumber,
    technicalData,
    transportPrice: "Inkluderat i pris",
    assemblyPrice: "Inkluderat i pris",
    educationPrice: "Inkluderat i pris",
    descriptiveText: "",
  });

  const handleTechnicalDataChange = (index, key, value) => {
    const updatedTechnicalData = [...informationToUse.technicalData];
    updatedTechnicalData[index] = {
      ...updatedTechnicalData[index],
      [key]: value,
    };
    setInformationToUse((prevState) => ({
      ...prevState,
      technicalData: updatedTechnicalData,
    }));
  };

  const handleAddTechnicalData = () => {
    setInformationToUse((prevState) => ({
      ...prevState,
      technicalData: [...prevState.technicalData, { key: "", value: "" }],
    }));
  };

  const handleRemoveTechnicalData = (index) => {
    setInformationToUse((prevState) => ({
      ...prevState,
      technicalData: prevState.technicalData.filter((_, idx) => idx !== index),
    }));
  };

  return (
    <Modal
      header="Bekräfta uppgifter"
      showCloseButton={false}
      size="overflow-y-auto max-h-[95vh] max-w-4xl"
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="w-1/2">
            <label
              htmlFor="organisationNumber"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Organisationsnummer
            </label>
            <input
              type="text"
              id="organisationNumber"
              value={informationToUse.organisationNumber}
              onChange={(event) =>
                setInformationToUse((prevState) => ({
                  ...prevState,
                  organisationNumber: event.target.value,
                }))
              }
              className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="companyName"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Företagsnamn
            </label>
            <input
              type="text"
              id="companyName"
              value={informationToUse.companyName}
              onChange={(event) =>
                setInformationToUse((prevState) => ({
                  ...prevState,
                  companyName: event.target.value,
                }))
              }
              className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="w-1/2">
            <label
              htmlFor="companyReference"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Kundens referens
            </label>
            <input
              type="text"
              id="companyReference"
              value={informationToUse.companyReference}
              onChange={(event) =>
                setInformationToUse((prevState) => ({
                  ...prevState,
                  companyReference: event.target.value,
                }))
              }
              className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="companyReferenceTelephone"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Kundens telefonnummer
            </label>
            <input
              type="text"
              id="companyReferenceTelephone"
              value={informationToUse.companyReferenceTelephone}
              onChange={(event) =>
                setInformationToUse((prevState) => ({
                  ...prevState,
                  companyReferenceTelephone: event.target.value,
                }))
              }
              className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="ourReference"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Vår referens
            </label>
            <input
              type="text"
              id="ourReference"
              value={informationToUse.ourReference}
              onChange={(event) =>
                setInformationToUse((prevState) => ({
                  ...prevState,
                  ourReference: event.target.value,
                }))
              }
              className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="ourReferenceTelephone"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Vår referens telefonnummer
            </label>
            <input
              type="text"
              id="ourReferenceTelephone"
              value={informationToUse.ourReferenceTelephone}
              onChange={(event) =>
                setInformationToUse((prevState) => ({
                  ...prevState,
                  ourReferenceTelephone: event.target.value,
                }))
              }
              className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="w-1/3">
            <label
              htmlFor="priceInSek"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Pris i SEK
            </label>
            <input
              type="text"
              id="priceInSek"
              value={informationToUse.priceInSek}
              onChange={(event) =>
                setInformationToUse((prevState) => ({
                  ...prevState,
                  priceInSek: event.target.value,
                }))
              }
              className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
            />
          </div>
          <div className="w-1/3">
            <label
              htmlFor="newPriceInSek"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Nypris i SEK
            </label>
            <input
              type="text"
              id="newPriceInSek"
              value={informationToUse.newPriceInSek}
              onChange={(event) =>
                setInformationToUse((prevState) => ({
                  ...prevState,
                  newPriceInSek: event.target.value,
                }))
              }
              className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
            />
          </div>
          <div className="w-1/3">
            <label
              htmlFor="courseClause"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Kursklausul
            </label>
            <input
              type="text"
              id="courseClause"
              value={informationToUse.courseClause}
              onChange={(event) =>
                setInformationToUse((prevState) => ({
                  ...prevState,
                  courseClause: event.target.value,
                }))
              }
              className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="descriptiveText"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Beskrivande text
          </label>
          <textarea
            type="text"
            id="descriptiveText"
            value={informationToUse.descriptiveText}
            onChange={(event) =>
              setInformationToUse((prevState) => ({
                ...prevState,
                descriptiveText: event.target.value,
              }))
            }
            className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          />
        </div>
        <div>
          <label
            htmlFor="deliveryTime"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Leveranstid
          </label>
          <input
            type="text"
            id="deliveryTime"
            value={informationToUse.deliveryTime}
            onChange={(event) =>
              setInformationToUse((prevState) => ({
                ...prevState,
                deliveryTime: event.target.value,
              }))
            }
            className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          />
        </div>
        <div>
          <label
            htmlFor="paymentTerms"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Betalningsvillkor
          </label>
          <input
            type="text"
            id="paymentTerms"
            value={informationToUse.paymentTerms}
            onChange={(event) =>
              setInformationToUse((prevState) => ({
                ...prevState,
                paymentTerms: event.target.value,
              }))
            }
            className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          />
        </div>
        <div>
          <label
            htmlFor="validTime"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Giltighetstid
          </label>
          <input
            type="text"
            id="validTime"
            value={informationToUse.validTime}
            onChange={(event) =>
              setInformationToUse((prevState) => ({
                ...prevState,
                validTime: event.target.value,
              }))
            }
            className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          />
        </div>
        <div>
          <label
            htmlFor="deliveryTerms"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Leveransvillkor
          </label>
          <input
            type="text"
            id="deliveryTerms"
            value={informationToUse.deliveryTerms}
            onChange={(event) =>
              setInformationToUse((prevState) => ({
                ...prevState,
                deliveryTerms: event.target.value,
              }))
            }
            className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          />
        </div>
        <div>
          <label
            htmlFor="quotationNumber"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Offertnummer
          </label>
          <input
            type="number"
            id="quotationNumber"
            value={informationToUse.quotationNumber}
            onChange={(event) =>
              setInformationToUse((prevState) => ({
                ...prevState,
                quotationNumber: event.target.value,
              }))
            }
            className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          />
        </div>
        <div>
          <label
            htmlFor="transportPrice"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Frakt
          </label>
          <input
            type="text"
            id="transportPrice"
            value={informationToUse.transportPrice}
            onChange={(event) =>
              setInformationToUse((prevState) => ({
                ...prevState,
                transportPrice: event.target.value,
              }))
            }
            className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          />
        </div>
        <div>
          <label
            htmlFor="assemblyPrice"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Montage
          </label>
          <input
            type="text"
            id="assemblyPrice"
            value={informationToUse.assemblyPrice}
            onChange={(event) =>
              setInformationToUse((prevState) => ({
                ...prevState,
                assemblyPrice: event.target.value,
              }))
            }
            className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          />
        </div>
        <div>
          <label
            htmlFor="educationPrice"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Utbildning
          </label>
          <input
            type="text"
            id="educationPrice"
            value={informationToUse.educationPrice}
            onChange={(event) =>
              setInformationToUse((prevState) => ({
                ...prevState,
                educationPrice: event.target.value,
              }))
            }
            className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          />
        </div>
        {informationToUse.technicalData.map((data, index) => (
          <div key={index} className="flex flex-col md:flex-row md:space-x-4">
            <div className="w-1/2">
              <label
                htmlFor={`key-${index}`}
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Nyckel
              </label>
              <input
                type="text"
                id={`key-${index}`}
                value={data.key}
                onChange={(event) =>
                  handleTechnicalDataChange(index, "key", event.target.value)
                }
                className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
              />
            </div>
            <div className="w-1/2">
              <label
                htmlFor={`value-${index}`}
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Värde
              </label>
              <input
                type="text"
                id={`value-${index}`}
                value={data.value}
                onChange={(event) =>
                  handleTechnicalDataChange(index, "value", event.target.value)
                }
                className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
              />
            </div>
            <div className="mt-[27px]">
              <button
                onClick={() => handleRemoveTechnicalData(index)}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
              >
                X
              </button>
            </div>
          </div>
        ))}
        <div>
          <button
            onClick={handleAddTechnicalData}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition duration-300"
          >
            +
          </button>
        </div>
        <Button onClick={() => onComplete(informationToUse)}>Skapa PDF</Button>
      </div>
    </Modal>
  );
};

const formattedPrice = (price) =>
  new Intl.NumberFormat("sv-SE", {
    style: "currency",
    currency: "SEK",
  }).format(price);

export default CreateQuotation;
