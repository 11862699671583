import React, { useContext, useMemo } from "react";
import { LayoutContext } from "../context/LayoutContext";

const range = (start, end) => {
  let length = end - start + 1;

  return Array.from({ length }, (_, idx) => idx + start);
};

const DOTS = "...";

const Pagination = ({ currentPage, totalPageCount, onPageChange, sx }) => {
  const { isDesktopLayout } = useContext(LayoutContext);
  const siblingCount = useMemo(
    () => (isDesktopLayout ? 3 : 1),
    [isDesktopLayout]
  );

  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [currentPage, totalPageCount, siblingCount]);

  const previousPageAvailable = currentPage > 1;
  const nextPageAvailable = currentPage < totalPageCount;

  return (
    <div className={`flex gap-1 sm:gap-2 overflow-x-auto ${sx ? sx : ""}`}>
      <div
        className={`flex justify-center items-center h-6 w-8 sm:h-8 sm:w-12 border border-primary text-xs sm:text-sm ${
          !previousPageAvailable
            ? "opacity-50 cursor-not-allowed"
            : "cursor-pointer"
        }`}
        onClick={() => previousPageAvailable && onPageChange(currentPage - 1)}
      >
        {"<"}
      </div>
      {paginationRange.map((page, index) => {
        const selected = page === currentPage;
        const isPage = page !== DOTS;

        return (
          <div
            key={index}
            className={`flex justify-center items-center h-6 w-8 sm:h-8 sm:w-12 border border-primary text-xs sm:text-sm ${
              isPage && "sm:hover:bg-primary sm:hover:text-white"
            } cursor-pointer ${selected && "bg-primary text-white"}`}
            onClick={() => isPage && onPageChange(page)}
          >
            {page}
          </div>
        );
      })}
      <div
        className={`flex justify-center items-center h-6 w-8 sm:h-8 sm:w-12 border border-primary text-xs sm:text-sm ${
          !nextPageAvailable
            ? "opacity-50 cursor-not-allowed"
            : "cursor-pointer"
        }`}
        onClick={() => nextPageAvailable && onPageChange(currentPage + 1)}
      >
        {">"}
      </div>
    </div>
  );
};

export default Pagination;
