import React, { useState } from "react";
import Button from "./button";

const COOKIES_ACCEPTED_KEY = "cookiesAccepted";
const COOKIES_ACCEPTANCE_ANSWERED_KEY = "cookiesAcceptanceAnswered";

const CookieConsent = () => {
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(
    localStorage.getItem(COOKIES_ACCEPTED_KEY) == "true"
  );

  const [hasAnsweredCookiesAcceptance, setHasAnsweredCookiesAcceptance] =
    useState(localStorage.getItem(COOKIES_ACCEPTANCE_ANSWERED_KEY) == "true");

  return (
    !hasAnsweredCookiesAcceptance && (
      <div className="fixed bottom-4 right-4 bg-primary z-50 flex justify-center max-w-xs sm:max-w-xl p-4 flex flex-col gap-2">
        <div className="text-white text-sm w-full">
          Vi använder cookies för att förbättra din upplevelse samt i
          marknadsföringssyfte.
        </div>
        <div className="flex gap-2">
          <Button
            black
            onClick={() => {
              setHasAcceptedCookies(true);
              setHasAnsweredCookiesAcceptance(true);
              localStorage.setItem(COOKIES_ACCEPTED_KEY, true);
              localStorage.setItem(COOKIES_ACCEPTANCE_ANSWERED_KEY, true);
            }}
          >
            Godkänn alla
          </Button>
          <Button
            black
            onClick={() => {
              setHasAcceptedCookies(false);
              setHasAnsweredCookiesAcceptance(true);
              localStorage.setItem(COOKIES_ACCEPTED_KEY, false);
              localStorage.setItem(COOKIES_ACCEPTANCE_ANSWERED_KEY, true);
            }}
          >
            Neka alla
          </Button>
        </div>
      </div>
    )
  );
};

export default CookieConsent;
