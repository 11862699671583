import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Fuse from "fuse.js";
import { MachineContext } from "../context/MachineContext";
import { LayoutContext } from "../context/LayoutContext";
import MachinePreview from "../components/machinePreview";
import routes from "./routes";
import Manufacturers from "../components/manufacturers";
import { MachineIcon, AllmachinesIcon } from "../components/machineIcon";
import Heading from "../components/heading";
import { Helmet } from "react-helmet-async";

const HeroSection = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [fuseInstance, setFuseInstance] = useState(null);
  const { machines } = useContext(MachineContext);
  const { isDesktopLayout } = useContext(LayoutContext);

  const searchRef = useRef(null);

  const MAX_SEARCH_RESULTS = isDesktopLayout ? 10 : 5;

  useEffect(() => {
    setFuseInstance(
      new Fuse(machines, {
        keys: ["title", "category"],
      })
    );
  }, [machines]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchResults([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  return (
    <div className="relative text-white h-[40vh] overflow-visible flex">
      <div className="flex-1 bg-secondary flex items-center justify-center overflow-visible p-4">
        <div className="relative overflow-visible">
          <h1 className="text-3xl lg:text-5xl font-bold mb-8">
            Störst på begagnade maskiner i Sverige
          </h1>
          <div className="flex flex-col items-center relative" ref={searchRef}>
            <input
              type="text"
              placeholder="Hitta din maskin här"
              className="px-5 py-2 border border-white text-black w-full max-w-md outline-none"
              onChange={(event) => {
                setSearchResults(fuseInstance.search(event.target.value));
              }}
            />
            {searchResults.length > 0 && (
              <div className="absolute top-full bg-white text-black w-full max-w-md z-10 shadow-lg border-t border-t-[0.1px] border-gray-200">
                {searchResults
                  .slice(0, MAX_SEARCH_RESULTS)
                  .map((result, index) => (
                    <Link key={index} to={`/machines/${result.item.id}`}>
                      <div className="p-2 hover:bg-gray-200 cursor-pointer">
                        <div>{result.item.title}</div>
                        <div className="flex gap-4">
                          <strong>{result.item.category}</strong>
                          <strong>{result.item.condition}</strong>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CategoryGridSection = () => {
  const { machineCategories } = useContext(MachineContext);
  const { isDesktopLayout } = useContext(LayoutContext);

  const MAX_CATEGORIES_TO_SHOW = isDesktopLayout ? 13 : 5;

  const categoriesToShow = [
    "CNC",
    "Kantlistmaskin",
    "Plattuppdelningssåg",
    "Bredbandputs",
    "Spånsug",
    "Listhyvel",
    "CNC borrmaskin",
    "Justersåg",
    "Väggsåg",
    "Kapsåg",
    "Fladderputs",
    "Lackering",
    "Planhyvel",
  ];

  return (
    <div className="bg-white p-4 sm:p-16">
      <div className="container mx-auto max-w-7xl">
        <div className="flex mb-6">
          <Heading>Kategorier</Heading>
          <Link to="/catalogue" className="bg-black text-white p-1 self-start">
            Visa alla
          </Link>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-4">
          {categoriesToShow
            .map((category) => {
              return (
                machineCategories.find(
                  (m) => m.name.toLowerCase() === category.toLowerCase()
                ) || {
                  name: category,
                  amountOfRelatedMachines: 0,
                }
              );
            })
            .sort(
              (a, b) => b.amountOfRelatedMachines - a.amountOfRelatedMachines
            )
            .slice(0, MAX_CATEGORIES_TO_SHOW)
            .map((category, index) => (
              <Category
                key={index}
                name={category.name}
                count={category.amountOfRelatedMachines}
              />
            ))}
          <div
            key={machineCategories.length + 1}
            className="border max-h-36 max-w-36"
          >
            <Link
              to={routes.usedMachines.url}
              className="flex flex-col items-center justify-center p-4"
            >
              <AllmachinesIcon />
              <span className="text-xs">Visa alla kategorier</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const Category = ({ name, count, ...props }) => (
  <Link
    to={count > 0 ? `/catalogue?category=${name}` : "/catalogue"}
    className="flex flex-col items-center justify-center p-4 border relative max-h-36 max-w-36"
    data-testid={`category-${name}-link`}
    {...props}
  >
    {count > 0 && (
      <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-primary rounded-full h-8 w-8 flex items-center justify-center">
        <span className="text-white text-xs font-semibold">{count}</span>
      </div>
    )}
    <div className="flex flex-col items-center">
      <MachineIcon type={name} />
      <span className="text-xs">{name}</span>
    </div>
  </Link>
);

const FeaturedSection = () => {
  const { machines } = useContext(MachineContext);

  const sortedMachines = machines
    .filter((machine) => machine.featured)
    .sort((a, b) => new Date(b.lastChangeDate) - new Date(a.lastChangeDate))
    .slice(0, 4);

  if (sortedMachines.length <= 0) {
    return null;
  }

  return (
    <div className="bg-secondary p-4 sm:p-16">
      <div className="container mx-auto max-w-7xl">
        <div className="flex mb-6">
          <Heading>Utvalda maskiner</Heading>
          <Link
            to="/catalogue"
            className="bg-black text-white p-1 self-start border-r border-t border-b border-primary"
          >
            Visa alla
          </Link>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {sortedMachines.map((machine) => (
            <MachinePreview machine={machine} key={machine.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

const NewArrivalsSection = () => {
  const { machines } = useContext(MachineContext);

  const sortedMachines = machines
    .sort((a, b) => new Date(b.lastChangeDate) - new Date(a.lastChangeDate))
    .slice(0, 4);

  return (
    <div className="bg-secondary p-4 sm:p-16">
      <div className="container mx-auto max-w-7xl">
        <div className="flex mb-6">
          <Heading>Nyinkommet</Heading>
          <Link
            to="/catalogue"
            className="bg-black text-white p-1 self-start border-r border-t border-b border-primary"
          >
            Visa alla
          </Link>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {sortedMachines.map((machine) => (
            <MachinePreview machine={machine} key={machine.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>AS Maskiner</title>
        <meta
          name="description"
          content="Välkommen till AS Maskiner - ledande inom begagnade kvalitetsmaskiner för trä- och plastindustrin i Sverige. Upptäck vårt omfattande utbud från toppmärken som SCM, Homag och Biesse, och upplev en enklare, säkrare köpprocess med vårt expertteam. Utforska Sveriges största urval av CNC-maskiner, kantlistmaskiner och mycket mer, allt tillgängligt genom våra exklusiva europeiska partnerskap. Börja din sökning idag och ta ditt företag till nästa nivå."
        />
        <meta
          name="keywords"
          content="AS Maskiner, begagnade maskiner, begagnad snickerimaskin, CNC fräs, Homag CNC, Biesse CNC, SCM CNC, Morbidelli CNC, begagnad CNC, begagnad plattuppdelningssåg, begagnad bredbandputs, begagnad kantlistmaskin, begagnad listhyvel, begagnad hyvel, begagnad justersåg, begagnad spånsug, begagnad väggsåg, begagnad fanérpress"
        />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "WebPage",
            "url": "https://www.asmaskiner.se",
            "name": "AS Maskiner",
            "description": "Välkommen till AS Maskiner - ledande inom begagnade kvalitetsmaskiner för trä- och plastindustrin i Sverige. Upptäck vårt omfattande utbud från toppmärken som SCM, Homag och Biesse, och upplev en enklare, säkrare köpprocess med vårt expertteam. Utforska Sveriges största urval av CNC-maskiner, kantlistmaskiner och mycket mer, allt tillgängligt genom våra exklusiva europeiska partnerskap. Börja din sökning idag och ta ditt företag till nästa nivå."
          }`}
        </script>
      </Helmet>
      <HeroSection />
      <CategoryGridSection />
      <NewArrivalsSection />
      <FeaturedSection />
      <Manufacturers />
    </>
  );
}
