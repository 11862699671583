import React, { useState, useEffect, useContext, useCallback } from "react";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { MachineContext } from "../context/MachineContext";
import MachinePreview from "../components/machinePreview";
import Checkbox from "../components/checkbox";
import Pagination from "../components/pagination";
import { LayoutContext } from "../context/LayoutContext";
import Button from "../components/button";
import Heading from "../components/heading";
import { Helmet } from "react-helmet-async";

const MAX_ITEMS_PER_PAGE = 12;

const generateYearOptions = (start, end) => {
  const years = [];
  for (let year = start; year <= end; year++) {
    years.push(year);
  }
  return years;
};

const Catalogue = () => {
  const [filters, setFilters] = useState({
    conditions: [],
    manufacturers: [],
    categories: [],
    buildYear: { start: "", end: "" },
  });
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();
  const { isDesktopLayout } = useContext(LayoutContext);
  const [mobileFilterMenuIsOpen, setMobileFilterMenuIsOpen] = useState(false);

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const condition = searchParams.get("condition");

    if (!!condition && ["Ny", "Använd"].includes(condition)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        conditions: [condition],
      }));
    }

    const category = searchParams.get("category");

    if (!!category) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        categories: [category],
      }));
    }

    const manufacturer = searchParams.get("manufacturer");

    if (!!manufacturer) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        manufacturers: [manufacturer],
      }));
    }

    setInitialized(true);
  }, [location.search]);

  const {
    machines,
    machineManufacturers,
    machineCategories,
    buildYearInterval,
  } = useContext(MachineContext);

  useEffect(() => {
    if (machines.length > 0) {
      setFilteredMachines(machines);
    }
  }, [machines]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredMachines.length / MAX_ITEMS_PER_PAGE) || 1);
    setCurrentPage(1);
  }, [filteredMachines]);

  const filterByCondition = useCallback(
    (machine) => {
      if (filters.conditions.length > 0) {
        return filters.conditions.includes(machine.condition);
      } else {
        return true;
      }
    },
    [filters.conditions]
  );

  const filterByManufacturer = useCallback(
    (machine) => {
      if (filters.manufacturers.length > 0) {
        return filters.manufacturers.includes(machine.manufacturer);
      } else {
        return true;
      }
    },
    [filters.manufacturers]
  );

  const filterByCategory = useCallback(
    (machine) => {
      if (filters.categories.length > 0) {
        return filters.categories.includes(machine.category);
      } else {
        return true;
      }
    },
    [filters.categories]
  );

  const filterByBuildYear = useCallback(
    (machine) => {
      if (
        !!filters.buildYear.start &&
        machine.buildYear < filters.buildYear.start
      ) {
        return false;
      }

      if (
        !!filters.buildYear.end &&
        machine.buildYear > filters.buildYear.end
      ) {
        return false;
      }

      return true;
    },
    [filters.buildYear]
  );

  useEffect(() => {
    setFilteredMachines(
      machines.filter(
        (machine) =>
          filterByCondition(machine) &&
          filterByManufacturer(machine) &&
          filterByCategory(machine) &&
          filterByBuildYear(machine)
      )
    );
  }, [filters, machines]);

  {
    /** TODO: Move to mobile overlay component */
  }
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (mobileFilterMenuIsOpen) {
      document.body.classList.add("lock-scroll");
    } else {
      document.body.classList.remove("lock-scroll");
    }

    return () => (document.body.style.overflow = originalStyle);
  }, [mobileFilterMenuIsOpen]);

  const yearOptions = generateYearOptions(
    buildYearInterval.lowest,
    buildYearInterval.highest
  );

  return (
    initialized && (
      <>
        <Helmet>
          <title>AS Maskiner - Katalog</title>
          <meta
            name="description"
            content="Utforska vår omfattande katalog av högkvalitativa maskiner, både begagnade och nya, inklusive CNC, Kantlistmaskiner, Brebandsputs m.m. från välkända fabrikat så som HOMAG, HOLZ-HER, SCM och Biesse. Hitta de perfekta lösningarna för dina behov med vårt breda urval, konkurrenskraftiga priser och expertstöd."
          />
          <meta name="keywords" content="AS Maskiner, begagnade maskiner, CNC, Kantlistmaskiner, Bredbandsputs, HOMAG, HOLZ-HER, SCM, Biesse, begagnade CNC-maskiner, begagnade snickerimaskiner, industriella maskiner" />
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "ProductCatalogue",
              "url": "https://www.asmaskiner.se/catalogue",
              "name": "Produktkatalog",
              "description": "Utforska vår omfattande katalog av högkvalitativa maskiner, både begagnade och nya, inklusive CNC, Kantlistmaskiner, Brebandsputs m.m. från välkända fabrikat så som HOMAG, HOLZ-HER, SCM och Biesse. Hitta de perfekta lösningarna för dina behov med vårt breda urval, konkurrenskraftiga priser och expertstöd."
            }`}
          </script>
        </Helmet>
        <div className="container mx-auto p-4 sm:p-8">
          <Heading sx="mb-8 w-fit lg:w-1/5" type="h1">
            Produktkatalog
          </Heading>
          {!isDesktopLayout && (
            <div className="flex text-primary fixed top-20 right-2 z-20">
              <Button onClick={() => setMobileFilterMenuIsOpen(true)}>
                Visa filter
              </Button>
            </div>
          )}

          <div className="flex flex-col sm:flex-row gap-8">
            {isDesktopLayout && (
              <div className="basis-1/5">
                <div className="border p-2">
                  <CatalogueFilters
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    machineManufacturers={machineManufacturers}
                    machineCategories={machineCategories}
                    yearOptions={yearOptions}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col justify-content items-center sm:basis-4/5">
              <div className="flex mb-8 w-full">
                <div className="flex-grow">
                  <strong
                    className="sm:text-xl"
                    data-testid="number-of-filtered-machines"
                  >
                    {filteredMachines.length}
                  </strong>{" "}
                  filtrerade maskiner
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-1">
                {filteredMachines
                  .slice(
                    (currentPage - 1) * MAX_ITEMS_PER_PAGE,
                    MAX_ITEMS_PER_PAGE * currentPage
                  )
                  .map((machine) => (
                    <MachinePreview machine={machine} key={machine.id} />
                  ))}
              </div>
              {filteredMachines.length > MAX_ITEMS_PER_PAGE && (
                <Pagination
                  currentPage={currentPage}
                  totalPageCount={totalPages}
                  onPageChange={(number) => {
                    setCurrentPage(number);
                    window.scrollTo(0, 0);
                  }}
                  sx="mt-4 sm:mt-8"
                />
              )}
            </div>
          </div>
        </div>
        {mobileFilterMenuIsOpen && (
          <div className="fixed inset-0 z-50 bg-white">
            <div className="p-4 flex-row">
              {/** TODO: Move to icon component */}
              <svg
                className="w-6 h-6 ml-auto"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                onClick={() => setMobileFilterMenuIsOpen(false)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <CatalogueFilters
                filters={filters}
                handleFilterChange={handleFilterChange}
                machineManufacturers={machineManufacturers}
                machineCategories={machineCategories}
                yearOptions={yearOptions}
              />
              <Button
                onClick={() => setMobileFilterMenuIsOpen(false)}
                sx="mt-4 w-full"
              >
                Sök
              </Button>
            </div>
          </div>
        )}
      </>
    )
  );
};

const CatalogueFilters = ({
  filters,
  handleFilterChange,
  machineManufacturers,
  machineCategories,
  yearOptions,
}) => {
  return (
    <>
      <h4 className="font-semibold mb-2">Skick</h4>
      <div className="flex">
        <Checkbox
          label="Använd"
          sx="w-full"
          checked={filters.conditions.includes("Använd")}
          onCheck={() =>
            handleFilterChange("conditions", [...filters.conditions, "Använd"])
          }
          onUnCheck={() =>
            handleFilterChange(
              "conditions",
              filters.conditions.filter((condition) => condition !== "Använd")
            )
          }
        />
        <Checkbox
          label="Ny"
          sx="w-full"
          checked={filters.conditions.includes("Ny")}
          onCheck={() =>
            handleFilterChange("conditions", [...filters.conditions, "Ny"])
          }
          onUnCheck={() =>
            handleFilterChange(
              "conditions",
              filters.conditions.filter((condition) => condition !== "Ny")
            )
          }
        />
      </div>
      <hr className="mt-4 mb-4" />
      <h4 className="font-semibold mb-2">Fabrikat</h4>
      <Select
        onChange={(options) =>
          handleFilterChange(
            "manufacturers",
            options.map((option) => option.value)
          )
        }
        classNames={{
          input: () => "[&_input:focus]:ring-0",
        }}
        defaultValue={filters.manufacturers.map((manufacturer) => {
          return {
            label: manufacturer,
            value: manufacturer,
          };
        })}
        isMulti={true}
        placeholder="Välj"
        noOptionsMessage={() => "Inga resultat"}
        options={machineManufacturers.map((manufacturer) => {
          return {
            label: manufacturer.name,
            value: manufacturer.name,
          };
        })}
      />
      <hr className="mt-4 mb-4" />
      <h4 className="font-semibold mb-2">Kategori</h4>
      <Select
        onChange={(options) =>
          handleFilterChange(
            "categories",
            options.map((option) => option.value)
          )
        }
        classNames={{
          input: () => "[&_input:focus]:ring-0",
        }}
        defaultValue={filters.categories.map((category) => {
          return {
            label: category,
            value: category,
          };
        })}
        isMulti={true}
        placeholder="Välj"
        noOptionsMessage={() => "Inga resultat"}
        options={machineCategories.map((category) => {
          return {
            label: category.name,
            value: category.name,
          };
        })}
      />
      <hr className="mt-4 mb-4" />
      <h4 className="font-semibold mb-2">Årsmodell</h4>
      <div className="flex gap-2">
        <select
          value={filters.buildYear.start}
          onChange={(e) =>
            handleFilterChange("buildYear", {
              start: e.target.value,
              end: filters.buildYear.end,
            })
          }
          className="border border-gray-300 p-2 w-full"
        >
          <option value="">Från</option>
          {yearOptions.map((year) => (
            <option
              key={year}
              value={year}
              disabled={!!filters.buildYear.end && filters.buildYear.end < year}
            >
              {year}
            </option>
          ))}
        </select>
        <select
          value={filters.buildYear.end}
          onChange={(e) =>
            handleFilterChange("buildYear", {
              start: filters.buildYear.start,
              end: e.target.value,
            })
          }
          className="border border-gray-300 p-2 w-full"
        >
          <option value="">Till</option>
          {yearOptions.map((year) => (
            <option
              key={year}
              value={year}
              disabled={
                !!filters.buildYear.start && filters.buildYear.start > year
              }
            >
              {year}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default Catalogue;
