const Modal = ({
  onClose,
  header,
  showCloseButton = true,
  children,
  size = "w-11/12 sm:w-1/2 max-w-2xl",
  sx = "",
}) => {
  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center overflow-y-auto ${sx}`}
    >
      <div className={`bg-white p-4 sm:p-8 ${size}`}>
        <div className="flex">
          <h3 className="font-bold mb-4 sm:mb-8 text-xl sm:text-2xl flex-grow">
            {header}
          </h3>
          <svg
            className="w-6 h-6 cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        {children}
        {showCloseButton && (
          <button
            onClick={onClose}
            className="bg-primary text-white py-2 px-4 hover:bg-orange-600 transition duration-300 ease-in-out mt-4 sm:mt-8"
          >
            Stäng
          </button>
        )}
      </div>
    </div>
  );
};

export default Modal;
