const buildMachineProperties = (machines) => {
  const foundCategories = {};
  const foundManufacturers = {};
  const foundBuildYearInterval = {
    lowest: new Date().getFullYear(),
    highest: 1900,
  };

  machines.forEach((machine) => {
    if (!!machine.category) {
      if (foundCategories[machine.category]) {
        foundCategories[machine.category] =
          foundCategories[machine.category] + 1;
      } else {
        foundCategories[machine.category] = 1;
      }
    }

    if (!!machine.manufacturer) {
      if (foundManufacturers[machine.manufacturer]) {
        foundManufacturers[machine.manufacturer] =
          foundManufacturers[machine.manufacturer] + 1;
      } else {
        foundManufacturers[machine.manufacturer] = 1;
      }
    }

    if (!!machine.buildYear) {
      if (foundBuildYearInterval.lowest > machine.buildYear) {
        foundBuildYearInterval.lowest = machine.buildYear;
      }

      if (foundBuildYearInterval.highest < machine.buildYear) {
        foundBuildYearInterval.highest = machine.buildYear;
      }
    }
  });

  return {
    foundCategories,
    foundManufacturers,
    foundBuildYearInterval,
  };
};

export { buildMachineProperties };
